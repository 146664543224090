import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    apiAuthTokenAuthCreate: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: `/api/auth/token-auth/`,
        method: 'POST',
        body: queryArg,
      });
      endpoint.invalidatesTags = ['Profile', 'Electricbuses', 'VehicleGroups'];
    },
    //      query: () => ({ url: `/api/users/self` }),

    apiUsersSelfRetrieve: (endpoint) => {
      endpoint.query = () => `/api/users/self`;
      endpoint.providesTags = ['Profile'];
    },
    apiUsersListList: (endpoint) => {
      (endpoint.query = () => `/api/users/list/`),
        (endpoint.providesTags = ['Users']);
    },
    apiUsersCreateCreate: (endpoint) => {
      endpoint.query = (body) => ({
        url: `/api/users/create/`,
        method: 'POST',
        body,
      });
      endpoint.invalidatesTags = ['Users'];
    },
    apiUsersUpdatePartialUpdate: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: `/api/users/${queryArg.username}/update/`,
        method: 'PATCH',
        body: queryArg.patchedUserUpdate,
      });
      endpoint.invalidatesTags = ['Users', 'Profile'];
    },
    apiUsersSelfPartialUpdate: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: `/api/users/self`,
        method: 'PATCH',
        body: queryArg,
      });
      endpoint.invalidatesTags = ['Users', 'Profile'];
    },
    apiUsersDeleteDestroy: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: `/api/users/${queryArg}/delete/`,
        method: 'DELETE',
      });
      endpoint.invalidatesTags = ['Users'];
    },
    apiLoggerSystemList: (endpoint) => {
      endpoint.query = (params) => {
        const {
          actions,
          dateFrom,
          dateTo,
          page,
          pageSize,
          service,
          sortedBy,
          sortedField,
          userIds,
        } = params;

        let formattedSortedField: 'action' | 'user' | 'datetime' | undefined;

        switch (sortedField as 'actor' | 'timestamp') {
          case 'actor':
            formattedSortedField = 'user';
            break;
          case 'timestamp':
            formattedSortedField = 'datetime';
            break;
          default:
            formattedSortedField = sortedField;
        }

        return {
          url: `/api/logger_system/`,
          params: {
            actions,
            date_from: dateFrom,
            date_to: dateTo,
            page,
            page_size: pageSize,
            service,
            sorted_by: sortedBy,
            sorted_field: formattedSortedField,
            user_ids: userIds,
          },
        };
      };
    },
  },
});

export const {
  useApiAuthTokenAuthCreateMutation: useLoginMutation,
  useApiUsersSelfRetrieveQuery: useGetUsersInfoQuery,
  useApiUsersSelfPartialUpdateMutation: useUpdateProfileMutation,
  useApiUsersListListQuery: useGerUsersListQuery,
  useApiUsersCreateCreateMutation: useCreateUserMutation,
  useApiUsersUpdatePartialUpdateMutation: useUpdateUserMutation,
  useApiUsersDeleteDestroyMutation: useDeleteUserMutation,
  useApiLoggerSystemListQuery: useGetLogsQuery,
} = enhancedApi;
