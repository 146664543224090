import { FC, useState } from 'react';
import { ExpandingCell } from '@shared/ui/ui-expanding-cell';

type Props = {
  changes: string;
};

export const ChangesLogUser: FC<Props> = ({ changes }) => {
  const [isExpand, toggleExpand] = useState(false);
  // const isSingle = errors.length === 1;

  let changesArray: Array<string> = [];

  try {
    const parsedChanges = Object.entries(JSON.parse(changes));
    changesArray = parsedChanges.map(([entity, change]) => {
      const [prev, next] = change as [unknown, unknown];
      return `${entity}, ${prev} --> ${next}`;
    });
  } catch (e) {
    changesArray = [changes];
  }

  const result = changesArray.map((change, id) => {
    return <div key={`${change}-${id}`}>{change}</div>;
  });
  //  const result = errors.map(({ code_error, text_error }, id) => {
  //    return (
  //      <div key={`${id}-${code_error}-${text_error}`}>
  //        {code_error}: {text_error}
  //      </div>
  //    );
  //  });

  return (
    <ExpandingCell
      isSingle={result.length === 1}
      isExpand={isExpand}
      result={result}
      toggleExpand={toggleExpand}
    />
  );
};
