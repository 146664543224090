import { DriverOutputTable } from '@entities/driver/consts/types';
import { useMemo } from 'react';

type Props = {
  tableData: DriverOutputTable[];
  sortedBy: string | null;
  sortedField: keyof DriverOutputTable | null;
};

export const useSortDriversData = ({
  tableData,
  sortedBy,
  sortedField,
}: Props) => {
  const sortedVehicles = useMemo(() => {
    const clonedTableData = JSON.parse(
      JSON.stringify(tableData)
    ) as DriverOutputTable[];

    if (sortedBy === 'asc' && sortedField !== null) {
      switch (sortedField) {
        case 'is_active':
          return clonedTableData.sort((a, b) => {
            if (a.is_active === null || a.is_active === undefined) return 1;
            if (b.is_active === null || b.is_active === undefined) return -1;
            return Number(a.is_active) - Number(b.is_active);
          });
        case 'full_name':
          return clonedTableData.sort((a, b) => {
            if (a.full_name === null || a.full_name === undefined) return 1;
            if (b.full_name === null || b.full_name === undefined) return -1;

            const prevName = a.full_name;
            const nextName = b.full_name;
            return prevName.localeCompare(nextName, 'ru-RU');
          });
        case 'license_expiration_date':
          return clonedTableData.sort((a, b) => {
            if (
              a.license_expiration_date === undefined ||
              a.license_expiration_date === null
            )
              return 1;
            if (
              b.license_expiration_date === undefined ||
              b.license_expiration_date === null
            )
              return -1;
            return (
              new Date(a.license_expiration_date).getTime() -
              new Date(b.license_expiration_date).getTime()
            );
          });
        case 'park_name':
          return clonedTableData.sort((a, b) => {
            if (a.park_name === null || a.park_name === undefined) return 1;
            if (b.park_name === null || b.park_name === undefined) return -1;

            const prevName = a.park_name;
            const nextName = b.park_name;
            return prevName.localeCompare(nextName, 'ru-RU');
          });
        default:
          return clonedTableData.sort(
            (a, b) => Number(a[sortedField]) - Number(b[sortedField])
          );
      }
    }

    if (sortedBy === 'desc' && sortedField !== null) {
      switch (sortedField) {
        case 'is_active':
          return clonedTableData.sort((a, b) => {
            if (a.is_active === null || a.is_active === undefined) return 1;
            if (b.is_active === null || b.is_active === undefined) return -1;
            return Number(b.is_active) - Number(a.is_active);
          });
        case 'full_name':
          return clonedTableData.sort((a, b) => {
            if (a.full_name === null || a.full_name === undefined) return 1;
            if (b.full_name === null || b.full_name === undefined) return -1;

            const prevName = a.full_name;
            const nextName = b.full_name;
            return nextName.localeCompare(prevName, 'ru-RU');
          });
        case 'license_expiration_date':
          return clonedTableData.sort((a, b) => {
            if (
              a.license_expiration_date === undefined ||
              a.license_expiration_date === null
            )
              return 1;
            if (
              b.license_expiration_date === undefined ||
              b.license_expiration_date === null
            )
              return -1;
            return (
              new Date(b.license_expiration_date).getTime() -
              new Date(a.license_expiration_date).getTime()
            );
          });
        default:
          return clonedTableData.sort((a, b) => {
            if (a[sortedField] === null || a[sortedField] === undefined) {
              return 1;
            }
            if (b[sortedField] === null || b[sortedField] === undefined) {
              return -1;
            }
            return Number(b[sortedField]) - Number(a[sortedField]);
          });
      }
    }

    return clonedTableData;
  }, [tableData, sortedField, sortedBy]);

  return sortedVehicles;
};
