
  import { DriversHeader } from '@widgets/header/ui/drivers';
  import { ConnectedDriversTable } from '@features/drivers/table/ui/connected-table';
  import { StandardPageLayout } from '../styles';

  export function DriversPage() {
    return (
      <StandardPageLayout>
        <DriversHeader />
        <ConnectedDriversTable />
      </StandardPageLayout>
    );
  }
