import styled from 'styled-components';

export const TelemetryTableTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TelemetryTableTypeBlockTitle = styled.p`
  font-weight: 600;
  font-size: 17px;
`;
