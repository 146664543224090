import {
  FieldErrorContainer,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';
import { CustomedFormItemContainer, FilterFormWrapper } from './styles';
import { InputGroupContent } from '@features/drivers/filters/ui/form/styles';
import { useForm, Controller, type FieldValues } from 'react-hook-form';
import { CustomDatetimepicker } from '@shared/ui/custom-datetimepicker';
import {
  selectReleasesFilters,
  setFilters,
} from '@shared/redux/slices/releases/filters';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSchema, FormSchemaType } from '../../consts/schema';
import { CustomSelectMultiple } from '@shared/ui/custom-select-new';
import { useData } from '../../hooks/use-data';
import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { ConfigProvider, Spin } from 'antd';
import locale from 'antd/locale/ru_RU';
import theme from 'antd/es/theme';
import { resetPageByFiltering } from '@shared/utils/table/reset-page-by-filtering';
import { useSearchParams } from 'react-router-dom';

export const FilterForm = () => {
  const filters = useAppSelector(selectReleasesFilters);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: filters,
  });
  const { isLoading, error, parks } = useData();

  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');
  const parkIds = watch('parkIds');

  useEffect(() => {
    resetPageByFiltering(searchParams, setSearchParams);
    dispatch(
      setFilters({
        parkIds,
        dateFrom: dayjs.isDayjs(dateFrom)
          ? dateFrom.format('YYYY-MM-DD')
          : dateFrom,
        dateTo: dayjs.isDayjs(dateTo) ? dateTo.format('YYYY-MM-DD') : dateTo,
      })
    );
  }, [dateTo, dateFrom, parkIds]);

  useEffect(() => {
    resetPageByFiltering(searchParams, setSearchParams);
  }, [parkIds]);

  const parksOptions = useMemo(() => {
    if (!parks) return [];
    if (parks) {
      return parks.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }
  }, [parks]);

  if (isLoading) {
    return <>Загрузка...</>;
  }

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <form noValidate onChange={(e) => console.log('e', e)}>
        <FilterFormWrapper>
          <CustomedFormItemContainer>
            <FormItemLabel>По парку</FormItemLabel>
            <Controller
              name="parkIds"
              control={control}
              render={({ field, fieldState }) => (
                <CustomSelectMultiple
                  {...field}
                  options={parksOptions}
                  style={{
                    width: '200px',
                  }}
                />
              )}
            />
            <FieldErrorContainer>
              {errors?.parkIds && <p>{errors.parkIds.message}</p>}
            </FieldErrorContainer>
          </CustomedFormItemContainer>
          <FormItemContainer style={{ marginLeft: '20px' }}>
            <FormItemLabel>Диапазон времени</FormItemLabel>
            <InputGroupContent>
              <Controller
                name="dateFrom"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomDatetimepicker
                    {...field}
                    offShowTime={true}
                    value={dayjs(dateFrom)}
                    customFormat={'YYYY-MM-DD'}
                    allowClear={false}
                    style={{
                      width: '115px',
                    }}
                  />
                )}
              />
              {'-'}
              <Controller
                name="dateTo"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomDatetimepicker
                    {...field}
                    offShowTime={true}
                    value={dayjs(dateTo)}
                    customFormat={'YYYY-MM-DD'}
                    allowClear={false}
                    style={{
                      width: '115px',
                    }}
                  />
                )}
              />
            </InputGroupContent>
            <FieldErrorContainer>
              {errors?.dateFrom && <p>{errors.dateFrom.message}</p>}
              {errors?.dateTo && <p>{errors.dateTo.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>
        </FilterFormWrapper>
      </form>
    </ConfigProvider>
  );
};
