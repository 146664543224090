import {
  DriverOutputTable,
  ReleasesOutputTable,
} from '@entities/driver/consts/types';
import { useMemo } from 'react';

type Props = {
  tableData: ReleasesOutputTable[];
  sortedBy: string | null;
  sortedField: keyof ReleasesOutputTable | null;
};

const timeToSeconds = (time: string) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const useSortReleasesData = ({
  tableData,
  sortedBy,
  sortedField,
}: Props) => {
  const result = useMemo(() => {
    const clonedTableData = JSON.parse(
      JSON.stringify(tableData)
    ) as ReleasesOutputTable[];

    if (sortedBy === 'asc' && sortedField !== null) {
      switch (sortedField) {
        case 'full_name':
          return clonedTableData.sort((a, b) => {
            if (a.full_name === null || a.full_name === undefined) return 1;
            if (b.full_name === null || b.full_name === undefined) return -1;

            const prevName = a.full_name;
            const nextName = b.full_name;
            return prevName.localeCompare(nextName, 'ru-RU');
          });
        case 'garage_number':
          return clonedTableData.sort((a, b) => {
            if (a.garage_number === null || a.garage_number === undefined)
              return 1;
            if (b.garage_number === null || b.garage_number === undefined)
              return -1;
            return a.garage_number - b.garage_number;
          });
        case 'employee_number':
          return clonedTableData.sort((a, b) => {
            if (a.employee_number === null || a.employee_number === undefined)
              return 1;
            if (b.employee_number === null || b.employee_number === undefined)
              return -1;
            return Number(a.employee_number) - Number(b.employee_number);
          });
        case 'route':
          return clonedTableData.sort((a, b) => {
            if (a.route === null || a.route === undefined) return 1;
            if (b.route === null || b.route === undefined) return -1;

            const prevName = a.route;
            const nextName = b.route;
            return prevName.localeCompare(nextName, 'ru-RU');
          });
        case 'order_number':
          return clonedTableData.sort((a, b) => {
            if (a.order_number === null || a.order_number === undefined)
              return 1;
            if (b.order_number === null || b.order_number === undefined)
              return -1;
            return a.order_number - b.order_number;
          });
        case 'date':
          return clonedTableData.sort((a, b) => {
            if (a.date === undefined || a.date === null) return 1;
            if (b.date === undefined || b.date === null) return -1;
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          });
        case 'start_time':
          return clonedTableData.sort((a, b) => {
            if (a.start_time === undefined || a.start_time === null) return 1;
            if (b.start_time === undefined || b.start_time === null) return -1;
            return timeToSeconds(a.start_time) - timeToSeconds(b.start_time);
          });
        case 'end_time':
          return clonedTableData.sort((a, b) => {
            if (a.end_time === undefined || a.end_time === null) return 1;
            if (b.end_time === undefined || b.end_time === null) return -1;
            return timeToSeconds(a.end_time) - timeToSeconds(b.end_time);
          });
        case 'park_name':
          return clonedTableData.sort((a, b) => {
            if (a.park_name === null || a.park_name === undefined) return 1;
            if (b.park_name === null || b.park_name === undefined) return -1;

            const prevName = a.park_name;
            const nextName = b.park_name;
            return prevName.localeCompare(nextName, 'ru-RU');
          });
        default:
          return clonedTableData.sort(
            (a, b) => Number(a[sortedField]) - Number(b[sortedField])
          );
      }
    }

    if (sortedBy === 'desc' && sortedField !== null) {
      switch (sortedField) {
        case 'full_name':
          return clonedTableData.sort((a, b) => {
            if (a.full_name === null || a.full_name === undefined) return 1;
            if (b.full_name === null || b.full_name === undefined) return -1;

            const prevName = a.full_name;
            const nextName = b.full_name;
            return nextName.localeCompare(prevName, 'ru-RU');
          });
        case 'garage_number':
          return clonedTableData.sort((a, b) => {
            if (a.garage_number === null || a.garage_number === undefined)
              return 1;
            if (b.garage_number === null || b.garage_number === undefined)
              return -1;
            return b.garage_number - a.garage_number;
          });
        case 'employee_number':
          return clonedTableData.sort((a, b) => {
            if (a.employee_number === null || a.employee_number === undefined)
              return 1;
            if (b.employee_number === null || b.employee_number === undefined)
              return -1;
            return Number(b.employee_number) - Number(a.employee_number);
          });
        case 'order_number':
          return clonedTableData.sort((a, b) => {
            if (a.order_number === null || a.order_number === undefined)
              return 1;
            if (b.order_number === null || b.order_number === undefined)
              return -1;
            return b.order_number - a.order_number;
          });
        case 'date':
          return clonedTableData.sort((a, b) => {
            if (a.date === undefined || a.date === null) return 1;
            if (b.date === undefined || b.date === null) return -1;
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          });
        case 'start_time':
          return clonedTableData.sort((a, b) => {
            if (a.start_time === undefined || a.start_time === null) return 1;
            if (b.start_time === undefined || b.start_time === null) return -1;
            return timeToSeconds(b.start_time) - timeToSeconds(a.start_time);
          });
        case 'end_time':
          return clonedTableData.sort((a, b) => {
            if (a.end_time === undefined || a.end_time === null) return 1;
            if (b.end_time === undefined || b.end_time === null) return -1;
            return timeToSeconds(b.end_time) - timeToSeconds(a.end_time);
          });
        case 'park_name':
          return clonedTableData.sort((a, b) => {
            if (a.park_name === null || a.park_name === undefined) return 1;
            if (b.park_name === null || b.park_name === undefined) return -1;

            const prevName = a.park_name;
            const nextName = b.park_name;
            return nextName.localeCompare(prevName, 'ru-RU');
          });
        default:
          return clonedTableData.sort((a, b) => {
            if (a[sortedField] === null || a[sortedField] === undefined) {
              return 1;
            }
            if (b[sortedField] === null || b[sortedField] === undefined) {
              return -1;
            }
            return Number(b[sortedField]) - Number(a[sortedField]);
          });
      }
    }

    return clonedTableData;
  }, [tableData, sortedField, sortedBy]);

  return result;
};
