import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';
import { ReleasesOutputTable } from '@entities/driver/consts/types';

const columnHelper = createColumnHelper<ReleasesOutputTable>();

export const useColumns = () => {
  const [settings, setSettings] = useState<Record<string, boolean>>({
    full_name: true,
    garage_number: true,
    employee_number: true,
    route: true,
    order_number: true,
    date: true,
    start_time: true,
    end_time: true,
    park_name: true,
  });

  const DATA_COLUMNS = [
    columnHelper.accessor('full_name', {
      id: 'full_name',
      header: 'Водитель',
      size: 270,
      maxSize: 270,
      minSize: 270,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: true,
    }),
    columnHelper.accessor('garage_number', {
      id: 'garage_number',
      header: (
        <>
          <p>Гаражный</p>
          <p>номер</p>
        </>
      ) as unknown as string,
      size: 120,
      maxSize: 120,
      minSize: 120,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: true,
    }),
    columnHelper.accessor('employee_number', {
      id: 'employee_number',
      header: (
        <>
          <p>Табельный</p>
          <p>номер</p>
        </>
      ) as unknown as string,
      size: 130,
      maxSize: 130,
      minSize: 130,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: true,
    }),
    columnHelper.accessor('route', {
      id: 'route',
      header: 'Маршрут',
      size: 120,
      maxSize: 120,
      minSize: 120,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: false,
    }),
    columnHelper.accessor('order_number', {
      id: 'order_number',
      header: (
        <>
          <p>Номер</p>
          <p>наряда</p>
        </>
      ) as unknown as string,
      size: 100,
      maxSize: 100,
      minSize: 100,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: true,
    }),
    columnHelper.accessor('date', {
      id: 'date',
      header: 'Дата',
      size: 100,
      maxSize: 100,
      minSize: 100,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: true,
    }),
    columnHelper.accessor('start_time', {
      id: 'start_time',
      header: (
        <>
          <p>Начало</p>
          <p>работы</p>
        </>
      ) as unknown as string,
      size: 100,
      maxSize: 100,
      minSize: 100,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: true,
    }),
    columnHelper.accessor('end_time', {
      id: 'end_time',
      header: (
        <>
          <p>Окончание</p>
          <p>работы</p>
        </>
      ) as unknown as string,
      size: 130,
      maxSize: 130,
      minSize: 130,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: true,
    }),
    columnHelper.accessor('park_name', {
      id: 'park_name',
      header: 'Парк',
      size: 130,
      maxSize: 130,
      minSize: 130,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: true,
    }),
  ];

  const settingsColumn = getTableSettingsColumn({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      const typedId = id!;
      return {
        key: id as string,
        label: header as string,
        isChecked: settings[typedId],
      };
    }),
    settings,
    renderCell: (props) => null,
    setVisibleColumns: (cols: Record<string, boolean>) => {
      setSettings(cols);
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [settingsColumn, ...dataCols];
  }, [settings]);

  return visibleColumns;
};
