import { FiltersButton } from '@features/trackers/filters';
import { HeaderLayout } from '../../layout';
import { AddTrackerButton } from '../../../../features/trackers/add/ui/add-tracker-button';
import { GroupOperationsButton } from '@features/trackers/group-operations';
import { FC } from 'react';
import { RowSelectionState } from '@tanstack/react-table';

import { ContentWrapper, Outer, Title } from '../../consts/styles';

type Props = {
  selectedImeis: string[];
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export const TrackerHeader: FC<Props> = ({
  selectedImeis,
  setRowSelection,
}) => {
  const content = (
    <ContentWrapper>
      <Title>Трекеры</Title>
      <FiltersButton />
      <AddTrackerButton />
      <GroupOperationsButton
        setRowSelection={setRowSelection}
        selectedImeis={selectedImeis}
      />
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
};
