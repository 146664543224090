import { AddUserButton } from '@features/users/add/ui/button';
import { FiltersButton } from '@features/users/filters';

import { HeaderLayout } from '../../layout';
import { ContentWrapper, Outer, Title } from '../../consts/styles';

export const UsersHeader = () => {
  const content = (
    <ContentWrapper>
      <Title>Пользователи</Title>
      {/*<FiltersButton />*/}
      <AddUserButton />
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
};
