import { createColumnHelper, RowSelectionState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';
import { EditTrackerButton } from '@features/trackers/edit/ui/button';
import dayjs from 'dayjs';
import { useRole } from '@entities/user/lib/hooks/use-role';
import { DriverOutputTable } from '@entities/driver/consts/types';
import { EditDriverButton } from '@features/drivers/edit/ui/button';
import { ExpirationDate } from '@entities/driver/ui/expiration-date';
import { DriverActivity } from '@entities/driver/ui/driver-activity';

const columnHelper = createColumnHelper<DriverOutputTable>();

export const useColumns = () => {
  const {
    isDispatcher,
    isAdmin,
    isOperator,
    isLoading: isRoleLoading,
  } = useRole();

  const [settings, setSettings] = useState<Record<string, boolean>>({
    is_active: true,
    full_name: true,
    mobile: true,
    employee_number: true,
    personal_number: true,
    driving_license: true,
    license_expiration_date: true,
    park_name: true,
  });

  const DATA_COLUMNS = useMemo(() => {
    return [
      columnHelper.accessor('is_active', {
        id: 'is_active',
        header: 'Тип',
        size: 100,
        maxSize: 100,
        minSize: 100,
        cell: (props) => {
          return <DriverActivity isActive={props.getValue()} />;
        },
        enableSorting: true,
      }),
      columnHelper.accessor('full_name', {
        id: 'full_name',
        header: 'Водитель',
        size: 160,
        maxSize: 160,
        minSize: 160,
        cell: (props) => {
          const data = props.getValue() ?? 'Нет данных';

          const isActive = props.row.original.is_active;

          if (isActive) {
            return data;
          } else {
            return <p style={{ color: '#666E7E' }}>{data}</p>;
          }
        },
        enableSorting: true,
      }),
      columnHelper.accessor('mobile', {
        id: 'mobile',
        header: 'Телефон',
        size: 150,
        maxSize: 150,
        minSize: 150,
        cell: (props) => {
          const data = props.getValue() ?? 'Нет данных';

          const isActive = props.row.original.is_active;

          if (isActive) {
            return data;
          } else {
            return <p style={{ color: '#666E7E' }}>{data}</p>;
          }
        },
        enableSorting: false,
      }),
      columnHelper.accessor('employee_number', {
        id: 'employee_number',
        header: (
          <>
            <p>Табельный</p>
            <p>номер</p>
          </>
        ) as unknown as string,
        size: 150,
        maxSize: 150,
        minSize: 150,
        cell: (props) => {
          const data = props.getValue() ?? 'Нет данных';

          const isActive = props.row.original.is_active;

          if (isActive) {
            return data;
          } else {
            return <p style={{ color: '#666E7E' }}>{data}</p>;
          }
        },
        enableSorting: true,
      }),
      columnHelper.accessor('personal_number', {
        id: 'personal_number',
        header: (
          <>
            <p>Личный</p>
            <p>номер</p>
          </>
        ) as unknown as string,
        size: 130,
        maxSize: 130,
        minSize: 130,
        cell: (props) => {
          const data = props.getValue() ?? 'Нет данных';

          const isActive = props.row.original.is_active;

          if (isActive) {
            return data;
          } else {
            return <p style={{ color: '#666E7E' }}>{data}</p>;
          }
        },
        enableSorting: true,
      }),
      columnHelper.accessor('driving_license', {
        id: 'driving_license',
        header: (
          <>
            <p>Водительское</p>
            <p>удостоверение</p>
          </>
        ) as unknown as string,
        size: 180,
        maxSize: 180,
        minSize: 180,
        cell: (props) => {
          const driversLicence = props.getValue();
          if (driversLicence === null || driversLicence === undefined) {
            return 'Нет данных';
          }
          const isActive = props.row.original.is_active;

          if (isActive) {
            return `№ ${driversLicence}`;
          }

          return <p style={{ color: '#666E7E' }}>{driversLicence}</p>;
        },
        enableSorting: true, // разрешить сортировку после переноса сортировки с бэка на фронт
      }),
      columnHelper.accessor('license_expiration_date', {
        id: 'license_expiration_date',
        header: (
          <>
            <p>Срок действия</p>
            <p>вод. удостоверения</p>
          </>
        ) as unknown as string,
        size: 205,
        maxSize: 205,
        minSize: 205,
        cell: (props) => {
          return (
            <ExpirationDate
              isActive={props.row.original.is_active}
              expirationDate={props.getValue()}
            />
          );
        },
        enableSorting: true,
      }),
      columnHelper.accessor('park_name', {
        id: 'park_name',
        header: 'Парк',
        size: 130,
        maxSize: 130,
        minSize: 130,
        cell: (props) => {
          return props.getValue() ?? 'Нет данных';
        },
        enableSorting: true, // разрешить сортировку после переноса сортировки с бэка на фронт
      }),
    ];
  }, [isRoleLoading, isDispatcher, isAdmin]);
  // }, [parks, electricbuses, isDispatcher, isAdmin, isOperator, isRoleLoading]);

  const settingsColumn = useMemo(() => {
    return getTableSettingsColumn({
      columnHelper,
      columns: DATA_COLUMNS.map(({ id, header }) => {
        const typedId = id!;
        return {
          key: id as string,
          label: header as string,
          isChecked: settings[typedId],
        };
      }),
      settings,
      renderCell: (props) => {
        if (isRoleLoading) {
          return null;
        }

        if (isDispatcher || isAdmin) {
          return <EditDriverButton driver={props.row.original} />;
        }

        return null;
      },
      setVisibleColumns: (cols: Record<string, boolean>) => {
        setSettings(cols);
      },
    });
  }, [isRoleLoading, isDispatcher, isAdmin]);

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [settingsColumn, ...dataCols];
  }, [settings, isDispatcher, isAdmin, isOperator, isRoleLoading]);

  return visibleColumns;
};
