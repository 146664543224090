import { GroupOutput, ParkOutput } from '@shared/api/services/common/api';

export const getParkByGroupId = (
  groupId: number,
  parks: ParkOutput[]
): ParkOutput | undefined => {
  return parks.find((park) => park.groups?.find((el) => el.id === groupId));
};

export const getParkNameByParkId = (parkId: number, parks: ParkOutput[]) => {
  return parks.find((park) => park.id === parkId)?.name;
};

export const getParkNameByGroupId = (
  groupId: number,
  parks: ParkOutput[]
): string | undefined => {
  return getParkByGroupId(groupId, parks)?.name;
};
