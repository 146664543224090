import { useGerUsersListQuery } from '@shared/api/services/user/enhanced';

export function useData() {
  const {
    data: users,
    isLoading: isUsersLoading,
    error: usersError,
  } = useGerUsersListQuery();

  const error = [usersError].find((err) => err !== undefined);

  const isLoading = isUsersLoading;

  return {
    isLoading,
    error,
    users,
  };
}
