import { FormItemContainer } from '@shared/ui/form/styles';
import styled from 'styled-components';

export const FilterFormWrapper = styled.div`
  display: flex;
  padding-left: 20px;
  border-left: 1px solid #a3aec4;
`;

export const CustomedFormItemContainer = styled(FormItemContainer)`
  gap: 5px;
`;
