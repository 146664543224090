import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';

export function useData() {
  const {
    data: parks = [],
    isLoading: isParksLoading,
    isFetching: isParksFetching,
    isSuccess: isParksSuccess,
    error: parksError,
  } = useGetGroupsQuery(undefined);

  const error = [parksError].find((err) => err !== undefined);

  const isLoading = isParksLoading;

  return {
    isLoading,
    error,
    parks,
  };
}
