import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getTrackerByImeiApiTrackersImeiGet: (endpoint) => {
      (endpoint.query = (imei) => ({
        url: `tracker/api/trackers/${imei}`,
      })),
        (endpoint.providesTags = ['Trackers']);
    },
    getFirmwaresApiFirmwaresGet: (endpoint) => {
      (endpoint.query = () => ({
        url: `tracker/api/firmwares`,
      })),
        (endpoint.providesTags = ['Firmwares']);
    },
    getTrackersListApiTrackersListGet: (endpoint) => {
      (endpoint.query = () => ({
        url: `tracker/api/trackers/list`,
      })),
        (endpoint.providesTags = ['ListTrackers']);
    },
    getTrackersApiTrackersGet: (endpoint) => {
      (endpoint.query = (queryArg) => {
        return {
          url: `tracker/api/trackers`,
          params: {
            imei_like: queryArg.imeiLike,
            model_ids: queryArg.modelIds,
            statuses: queryArg.statuses,
            firmware: queryArg.firmware,
            sorted_field: queryArg.sortedField,
            sorted_by: queryArg.sortedBy,
            page: queryArg.page,
            size: queryArg.size,
          },
        };
      }),
        (endpoint.providesTags = ['Trackers']);
    },
    updateTrackersApiTrackersPatch: (endpoint) => {
      (endpoint.query = (body) => ({
        method: 'PATCH',
        url: `tracker/api/trackers`,
        body,
      })),
        (endpoint.invalidatesTags = ['Trackers', 'Electricbuses']);
    },
    deleteTrackerApiTrackersImeiDelete: (endpoint) => {
      (endpoint.query = (queryArg) => ({
        method: 'DELETE',
        url: `tracker/api/trackers/${queryArg}`,
      })),
        (endpoint.invalidatesTags = ['Electricbuses', 'Trackers']);
    },
    createTrackerApiTrackersPost: (endpoint) => {
      endpoint.query = (body) => ({
        method: 'POST',
        url: `tracker/api/trackers`,
        body,
      });
      endpoint.invalidatesTags = ['Trackers'];
    },
    trackerCommandsApiTrackersCommandsPost: (endpoint) => {
      endpoint.query = (body) => ({
        method: 'POST',
        url: `tracker/api/trackers/commands`,
        body,
      });
      endpoint.invalidatesTags = ['Trackers'];
    },
    trackerCommandsSettingsApiTrackersCommandsSettingsPost: (endpoint) => {
      endpoint.query = (body) => ({
        method: 'POST',
        url: `tracker/api/trackers/commands/settings`,
        body,
      });
      endpoint.invalidatesTags = ['Trackers'];
    },
  },
});

export const {
  useGetTrackerByImeiApiTrackersImeiGetQuery: useGetTrackerByImeiQuery,
  useGetTrackersApiTrackersGetQuery: useGetTrackersQuery,
  useLazyGetTrackersApiTrackersGetQuery: useLazyGetTrackersQuery,
  useUpdateTrackersApiTrackersPatchMutation: useUpdateTrackerMutation,
  useGetFirmwaresApiFirmwaresGetQuery: useGetFirmwaresQuery,
  useDeleteTrackerApiTrackersImeiDeleteMutation: useDeleteTrackerMutation,
  useCreateTrackerApiTrackersPostMutation: useCreateTrackerMutation,
  useGetTrackersListApiTrackersListGetQuery: useGetAllOfTheTrackersQuery,
  useTrackerCommandsApiTrackersCommandsPostMutation: useCommandTrackersMutation,
  useTrackerCommandsSettingsApiTrackersCommandsSettingsPostMutation:
    useCommandTrackersExtendedMutation,
} = enhancedApi;
