import { Flex } from 'antd';
import { Telemetry } from '@entities/vehicle/model/telemetry';
import { FC, useEffect } from 'react';
import { useData } from '../hooks/use-data';
import { VehicleStatusesRead } from '@shared/api/services/common/api';
import { LightningIcon } from '@shared/icons/lightning';

import {
  StyledContainer,
  VehicleInfoHeader,
  VehicleParams,
  VehicleSoc,
  VehicleExtraInfoParam,
  VehicleExtraInfoParamTitle,
  VehicleExtraInfoParamValue,
  FuelDistanceEndurance,
  BusRouteInfoWrapper,
  BusRouteValue,
  OutputInfoParam,
  DriverInfoContainer,
  DriverInfoKey,
  DriverInfoValue,
  RowBorder,
  StyledCollapse,
  SohValue,
} from './styles';
import { ArrowDownIcon } from '@shared/icons/arrow-down';
import { TrackerStatusBadge } from '@entities/vehicle/ui/tracker-status-badge';
import { VehicleStatusBadge } from '@entities/vehicle/ui/vehicle-status-badge';
import { ElectricbusTableItem } from '@features/vehicle/vehicles-table/consts/types';
import { useLazyReleaseWithDriver } from '@shared/api/services/drivers/enhanced';
import { ExpirationDate } from '@entities/driver/ui/expiration-date';
import { DriverActivity } from '@entities/driver/ui/driver-activity';

type Props = {
  trackerTelemetry: Telemetry;
  electricbuses: ElectricbusTableItem[];
};

export const VehicleInfoExpanded: FC<Props> = ({
  trackerTelemetry,
  electricbuses,
}) => {
  const imei = trackerTelemetry?.tracker_imei || '';

  const { tracker } = useData({ imei });

  const { status: trackerStatus } = tracker || {};

  const ev = electricbuses.find(({ imei: eBusImei }) => eBusImei === imei);

  const {
    garage_number,
    mileage,
    park_name,
    model,
    manufacturer,
    status,
    type,
  } = ev || {};

  const {
    charge_current_A, // суммарный ток заряда в текущий момент
    count_charge_session, // счётчик количества зарядных сессий
    ctts, // температура жидкости в термостате
    discharge_current_A, // суммарный ток разряда в текущий момент
    interval_telemetry_S, // сек, интервал в котором сообщения
    last_charge, // последняя зарядка когда была
    latitude,
    longitude,
    park_id,
    //  permissible_charge_current, // максимальный достустимый ток заряда в тек момент по всем батареям (суммарный по всем батареям)
    //  permissible_discharge_current, // максимальный достустимый ток разряда в тек момент по всем батареям (суммарный по всем батареям)
    power_reserve, // прогнозируемый остаток пробега (км)
    pre_latitude,
    pre_longitude,
    soc,
    soh,
    speed,
    time, // время отправки телеметрии (TODO: выпилить из апи)
    tracker_imei,
  } = trackerTelemetry || {};

  const [
    pullDriverInfo,
    { currentData: driverInfo, isLoading: isDriverInfoLoading },
  ] = useLazyReleaseWithDriver();

  useEffect(() => {
    if (
      garage_number !== undefined &&
      garage_number !== null &&
      park_id !== undefined &&
      park_id !== null
    ) {
      pullDriverInfo({
        garageNumber: garage_number,
        parkId: park_id,
      });
    }
  }, [garage_number, park_id]);

  const busRouteNumber = 808;

  return (
    <StyledContainer>
      <VehicleInfoHeader>
        <BusRouteInfoWrapper>
          <VehicleExtraInfoParamTitle>
            Маршрут {busRouteNumber}:
          </VehicleExtraInfoParamTitle>
          <BusRouteValue>Метро Алтуфьево, 1A → ДК Вперед</BusRouteValue>
        </BusRouteInfoWrapper>
        <VehicleParams>
          <VehicleExtraInfoParam>
            <VehicleExtraInfoParamTitle>
              Процент заряда:
            </VehicleExtraInfoParamTitle>
            <VehicleSoc percent={soc}>{soc} %</VehicleSoc>
          </VehicleExtraInfoParam>
          <VehicleExtraInfoParam>
            <VehicleExtraInfoParamTitle>Запас хода:</VehicleExtraInfoParamTitle>
            <FuelDistanceEndurance percent={power_reserve ?? 0}>
              {power_reserve ?? '-'} км
            </FuelDistanceEndurance>
          </VehicleExtraInfoParam>
          <VehicleExtraInfoParam>
            <VehicleExtraInfoParamTitle>Скорость:</VehicleExtraInfoParamTitle>
            <VehicleExtraInfoParamValue>
              {speed} км/ч
            </VehicleExtraInfoParamValue>
          </VehicleExtraInfoParam>
          <OutputInfoParam>
            <VehicleExtraInfoParamTitle>Пробег:</VehicleExtraInfoParamTitle>
            <VehicleExtraInfoParamValue>
              {mileage ? `${mileage} км` : 'Нет данных'}
            </VehicleExtraInfoParamValue>
          </OutputInfoParam>
          <VehicleExtraInfoParam>
            <VehicleExtraInfoParamTitle>
              Гаражный номер:
            </VehicleExtraInfoParamTitle>
            <VehicleExtraInfoParamValue>
              {garage_number}
            </VehicleExtraInfoParamValue>
          </VehicleExtraInfoParam>
        </VehicleParams>
      </VehicleInfoHeader>
      <div
        style={{
          border: '1px solid rgba(31,196,139,0.4)',
          borderRadius: '16px',
          marginLeft: '20px',
          marginRight: '20px',
          marginTop: '10px',
        }}
      >
        <StyledCollapse
          collapsible="header"
          defaultActiveKey={['driver-info']}
          items={[
            {
              key: 'driver-info',
              label: 'Водитель',
              children: (
                <DriverInfoContainer>
                  <DriverInfoKey>ФИО</DriverInfoKey>
                  <DriverInfoValue>
                    {isDriverInfoLoading
                      ? 'Загрузка...'
                      : driverInfo?.driver?.full_name ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Телефон</DriverInfoKey>
                  <DriverInfoValue>
                    {isDriverInfoLoading
                      ? 'Загрузка...'
                      : driverInfo?.driver?.mobile ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Личный номер</DriverInfoKey>
                  <DriverInfoValue>
                    {isDriverInfoLoading
                      ? 'Загрузка...'
                      : driverInfo?.driver?.personal_number ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Табельный номер</DriverInfoKey>
                  <DriverInfoValue>
                    {isDriverInfoLoading
                      ? 'Загрузка...'
                      : driverInfo?.driver?.employee_number ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Водительское удостоверение</DriverInfoKey>
                  <DriverInfoValue>
                    {' '}
                    {isDriverInfoLoading
                      ? 'Загрузка...'
                      : driverInfo?.driver?.driving_license ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>
                    Срок действия вод. удостоверения
                  </DriverInfoKey>
                  <DriverInfoValue>
                    {isDriverInfoLoading ? (
                      'Загрузка...'
                    ) : (
                      <ExpirationDate
                        isActive={driverInfo?.driver?.is_active}
                        expirationDate={
                          driverInfo?.driver?.license_expiration_date
                        }
                      />
                    )}
                  </DriverInfoValue>

                  <RowBorder />
                  <DriverInfoKey>Тип</DriverInfoKey>
                  <DriverInfoValue>
                    {isDriverInfoLoading ? (
                      'Загрузка...'
                    ) : (
                      <DriverActivity
                        isActive={driverInfo?.driver?.is_active}
                      />
                    )}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Номер наряда</DriverInfoKey>
                  <DriverInfoValue>
                    {isDriverInfoLoading
                      ? 'Загрузка...'
                      : driverInfo?.order_number ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Маршрут</DriverInfoKey>
                  <DriverInfoValue>
                    {isDriverInfoLoading
                      ? 'Загрузка...'
                      : driverInfo?.route ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Начало работы</DriverInfoKey>
                  <DriverInfoValue>
                    {isDriverInfoLoading
                      ? 'Загрузка...'
                      : driverInfo?.start_time ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Окончание работы</DriverInfoKey>
                  <DriverInfoValue>
                    {isDriverInfoLoading
                      ? 'Загрузка...'
                      : driverInfo?.end_time ?? 'Нет данных'}
                  </DriverInfoValue>
                </DriverInfoContainer>
              ),
            },
          ]}
          expandIconPosition="end"
          expandIcon={(_panelProps) => {
            return <ArrowDownIcon />;
          }}
        />
      </div>
      <div
        style={{
          border: '1px solid rgba(31,196,139,0.4)',
          borderRadius: '16px',
          marginLeft: '20px',
          marginRight: '20px',
          marginTop: '10px',
        }}
      >
        <StyledCollapse
          collapsible="header"
          defaultActiveKey={['vehicle-info']}
          items={[
            {
              key: 'vehicle-info',
              label: 'Свойства ТС',
              children: (
                <DriverInfoContainer>
                  <DriverInfoKey>Тип ТС</DriverInfoKey>
                  <DriverInfoValue>
                    {type?.name ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Статус трекера</DriverInfoKey>
                  <DriverInfoValue>
                    {trackerStatus && (
                      <TrackerStatusBadge status={trackerStatus} />
                    )}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Статус ТС</DriverInfoKey>
                  <DriverInfoValue>
                    {status !== undefined && status !== null ? (
                      <VehicleStatusBadge status={status} />
                    ) : null}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>
                    <Flex align="center">
                      Время последней
                      <span style={{ color: '#1FC48B' }}>
                        <LightningIcon />
                      </span>
                    </Flex>
                  </DriverInfoKey>
                  <DriverInfoValue>
                    {(last_charge as string | null | undefined) ?? 'нет данных'}
                  </DriverInfoValue>

                  {/*<RowBorder />

                  <DriverInfoKey>
                    <Flex align="center">
                      Плановое время
                      <span style={{ color: '#1FC48B' }}>
                        <LightningIcon />
                      </span>
                    </Flex>
                  </DriverInfoKey>
                  <DriverInfoValue>11:18 11.11.2024</DriverInfoValue>*/}

                  <RowBorder />

                  <DriverInfoKey>Производитель</DriverInfoKey>
                  <DriverInfoValue>
                    {manufacturer?.name ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Модель</DriverInfoKey>
                  <DriverInfoValue>
                    {model?.name ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Парк</DriverInfoKey>
                  <DriverInfoValue>{park_name ?? 'Нет данных'}</DriverInfoValue>
                </DriverInfoContainer>
              ),
            },
          ]}
          expandIconPosition="end"
          expandIcon={(_panelProps) => {
            return <ArrowDownIcon />;
          }}
        />
      </div>

      <div
        style={{
          border: '1px solid rgba(31,196,139,0.4)',
          borderRadius: '16px',
          marginLeft: '20px',
          marginRight: '20px',
          marginTop: '10px',
        }}
      >
        <StyledCollapse
          collapsible="header"
          defaultActiveKey={['vehicle-info']}
          items={[
            {
              key: 'vehicle-info',
              label: 'Телеметрия по ТС',
              children: (
                <DriverInfoContainer>
                  <DriverInfoKey>ID трекера</DriverInfoKey>
                  <DriverInfoValue>
                    {tracker_imei ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Суммарный ток заряда</DriverInfoKey>
                  <DriverInfoValue>
                    {charge_current_A ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Суммарный ток разряда</DriverInfoKey>
                  <DriverInfoValue>
                    {discharge_current_A ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>
                    Температура контура охлаждения (CTTS)
                  </DriverInfoKey>
                  <DriverInfoValue>{ctts ?? 'Нет данных'}</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>
                    Интервал обновления телеметрии (сек)
                  </DriverInfoKey>
                  <DriverInfoValue>
                    {interval_telemetry_S ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Кол-во циклов заряда/разряда</DriverInfoKey>
                  <DriverInfoValue>
                    {count_charge_session ?? 'Нет данных'}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Координаты</DriverInfoKey>
                  <DriverInfoValue>
                    {`${latitude} , ${longitude}`}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>SoH</DriverInfoKey>
                  <DriverInfoValue>
                    <SohValue percent={soh}>{`${soh} %`}</SohValue>
                  </DriverInfoValue>
                </DriverInfoContainer>
              ),
            },
          ]}
          expandIconPosition="end"
          expandIcon={(_panelProps) => {
            return <ArrowDownIcon />;
          }}
        />
      </div>
    </StyledContainer>
  );
};
