import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getHotStorageApiHotStorageGet: (endpoint) => {
      endpoint.query = (queryArg) => {
        return {
          url: `telemetry/api/hot_storage`,
          params: {
            park_ids: queryArg,
          },
        };
      };
      //    (endpoint.transformResponse = (
      //      response: HotStorageWithExtentFields[]
      //    ) => response.filter(({ ev }) => ev && ev.grn));
    },
    //    getTelemetryByFilterApiLatestTelemetriesGet: (endpoint) => {
    //      (endpoint.query = (queryArg) => {
    //        return {
    //          url: `telemetry/api/latest_telemetries`,
    //          params: {
    //            soc_from: queryArg.socFrom,
    //            soc_to: queryArg.socTo,
    //            soh_from: queryArg.sohFrom,
    //            soh_to: queryArg.sohTo,
    //            power_reserve_from: queryArg.powerReserveFrom,
    //            power_reserve_to: queryArg.powerReserveTo,
    //            is_with_tracker: queryArg.isWithTracker,
    //          },
    //        };
    //      }),
    //        (endpoint.providesTags = ['ListTelemetries']);
    //    },
    //    getBatteryTelemetriesApiBatteryGet: (endpoint) => {
    //      (endpoint.query = (queryArg) => {
    //        return {
    //          url: `telemetry/api/battery`,
    //          params: {
    //            page: queryArg.page,
    //            per_page: queryArg.perPage,
    //            sorted_field: queryArg.sortedField,
    //            sorted_by: queryArg.sortedBy,
    //          },
    //        };
    //      }),
    //        (endpoint.providesTags = ['BatteryListTelemetries']);
    //    },
    getTelemetriesReportApiReportGet: (endpoint) => {
      (endpoint.query = (queryArg) => {
        return {
          url: `telemetry/api/report`,
          params: {
            imei: queryArg.imei,
            date_from: queryArg.dateFrom,
            date_to: queryArg.dateTo,
          },
          responseHandler: (response) => {
            if (response.status === 400) {
              return response.json();
            }
            return response.blob();
          },
        };
      }),
        (endpoint.providesTags = ['reportTelemetry']);
    },
  },
});

export const {
  useGetHotStorageApiHotStorageGetQuery: useGetHotStorageTelemetryQuery,
  useLazyGetHotStorageApiHotStorageGetQuery: useLazyGetHotStorageTelemetryQuery,
  //  useLazyGetTelemetryByFilterApiLatestTelemetriesGetQuery:
  //    useLazyGetTelemetryQuery,
  //  useGetTelemetryByFilterApiLatestTelemetriesGetQuery: useGetTelemetryQuery,
  //  useGetBatteryTelemetriesApiBatteryGetQuery: useGetBatteryTelemetry,
  //  useLazyGetBatteryTelemetriesApiBatteryGetQuery: useLazyGetBatteryTelemetry,
  useLazyGetTelemetriesReportApiReportGetQuery: useLazyGetReportTelemetryQuery,
} = enhancedApi;
