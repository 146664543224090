import NiceModal from '@ebay/nice-modal-react';

import { EditIcon } from '@shared/icons/edit';
import { EditDriverModal } from '../modal';

import { StyledButton } from './styles';
import { DriverOutput } from '@shared/api/services/drivers/api';

type Props = {
  driver: DriverOutput; // TODO:
};

export function EditDriverButton({ driver }: Props) {
  const showModal = () => {
    NiceModal.show(EditDriverModal, { driver });
  };

  return (
    <StyledButton onClick={() => showModal()}>
      <EditIcon />
    </StyledButton>
  );
}
