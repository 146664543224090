import { useAddElectricbusMutation } from '@shared/api/services/electricbus/enhanced';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';

import { ConnectedVehicleForm } from '@entities/vehicle/ui/connected-form';
import { FormSchemaType } from '@entities/tracker/consts/schema';

import { ADD_SUCCESS, ADD_ERROR } from '../../const';
import { ConnectedTrackerForm } from '@entities/tracker/ui/connected-form';
import { useCreateTrackerMutation } from '@shared/api/services/tracker/enhanced';

type Props = {
  closeModal: () => void;
};

export function AddTracker({ closeModal }: Props) {
  const [trigger, { isLoading }] = useCreateTrackerMutation();

  const handleSubmit = async (data: FormSchemaType) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(`${ADD_SUCCESS} ${res.imei}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.detail : ADD_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <ConnectedTrackerForm
      submitButtonLabel="Добавить"
      handleFormSubmit={handleSubmit as (data: Partial<FormSchemaType>) => void}
      loading={isLoading}
    />
  );
}
