import { FC } from 'react';
import { ConfigProvider, Spin } from 'antd';
import { GetParksApiParksGetApiResponse } from '@shared/api/services/common/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import {
  FormButtonsContainer,
  FormLayout,
  MaskedPhoneNumberInput,
} from './styles';
import { UiButton } from '@shared/ui/ui-button';
import locale from 'antd/locale/ru_RU';
import theme from 'antd/es/theme';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';
import { CustomSelect } from '@shared/ui/custom-select-new';
import { CustomInput } from '@shared/ui/custom-input';
import { DriverOutput } from '@shared/api/services/drivers/api';
import {
  CreatingFormSchemaType,
  CreatingSchema,
  EditingFormSchemaType,
  EditingSchema,
} from '@entities/driver/consts/schema';
import { CustomDatetimepicker } from '@shared/ui/custom-datetimepicker';
import dayjs from 'dayjs';
import { StyledCheckbox } from '@shared/ui/custom-checkbox/styled';
import { InputMask } from '@react-input/mask';

type Props = {
  parks: GetParksApiParksGetApiResponse;
  driver?: DriverOutput;
  submitButtonLabel: string;
  handleFormSubmit: (
    data: Partial<EditingFormSchemaType> | CreatingFormSchemaType
  ) => Promise<void>;
  deleteButton?: JSX.Element;
  loading: boolean;
  formType: 'creating' | 'editing';
};

const parseFullName = (
  fullname: string
): {
  lastName?: string;
  firstName?: string;
  surname?: string;
} => {
  const [lastName, firstName, surname] = fullname.split(' ');
  return {
    lastName,
    firstName,
    surname,
  };
};

export const DriverForm: FC<Props> = ({
  parks,
  driver,
  submitButtonLabel,
  handleFormSubmit,
  deleteButton,
  loading,
  formType,
}) => {
  const defaultValues = driver
    ? {
        lastName: parseFullName(driver.full_name).lastName,
        firstName: parseFullName(driver.full_name).firstName,
        surname: parseFullName(driver.full_name).surname ?? '',
        mobile: driver.mobile,
        personalNumber: driver.personal_number,
        employeeNumber: driver.employee_number,
        drivingLicense: driver.driving_license,
        licenseExpirationDate:
          driver.license_expiration_date === null
            ? null
            : dayjs(driver.license_expiration_date),
        isActive: driver.is_active,
        parkId: driver.park_id,
      }
    : {};

  const isEditingFormType = formType === 'editing';
  const isCreatingFormType = formType === 'creating';

  const {
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm({
    resolver: isEditingFormType
      ? zodResolver(EditingSchema)
      : zodResolver(CreatingSchema),
    defaultValues,
  });

  const lastName = watch('lastName');
  const firstName = watch('firstName');
  const surname = watch('surname');
  const mobile = watch('mobile');
  const personalNumber = watch('personalNumber');
  const employeeNumber = watch('employeeNumber');
  const drivingLicense = watch('drivingLicense');
  const licenseExpirationDate = watch('licenseExpirationDate');
  const isActive = watch('isActive');
  const parkId = watch('parkId');

  const onSubmit = async (data: unknown) => {
    //@ts-ignore
    handleFormSubmit(data);
  };

  const handleReset = () => {
    if (isCreatingFormType) {
      setValue('lastName', undefined);
      setValue('firstName', undefined);
      setValue('surname', undefined);
      setValue('mobile', '');
      setValue('personalNumber', '');
      setValue('employeeNumber', '');
      setValue('drivingLicense', '');
    } else {
      if (driver) {
        setValue('lastName', parseFullName(driver.full_name).lastName);
        setValue('firstName', parseFullName(driver.full_name).firstName);
        setValue('surname', parseFullName(driver.full_name)?.surname ?? unde);
        setValue('mobile', driver.mobile);
        setValue('personalNumber', driver.personal_number);
        setValue('employeeNumber', driver.employee_number);
        setValue('drivingLicense', driver.driving_license);
        setValue(
          'licenseExpirationDate',
          driver.license_expiration_date === null
            ? null
            : dayjs(driver.license_expiration_date)
        );
        setValue('isActive', driver.is_active);
        setValue('parkId', driver.park_id);
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <ConfigProvider
        locale={locale}
        theme={{
          algorithm: theme.darkAlgorithm,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormLayout>
            <FormColumn>
              <FormItemContainer>
                <FormItemLabel>Фамилия</FormItemLabel>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      {...field}
                      inputSize="md"
                      placeholder="Введите фамилию"
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.lastName && <p>{errors.lastName.message}</p>}
                </FieldErrorContainer>
              </FormItemContainer>
              <FormItemContainer>
                <FormItemLabel>Имя</FormItemLabel>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      {...field}
                      inputSize="md"
                      placeholder="Введите имя"
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.firstName && <p>{errors.firstName.message}</p>}
                </FieldErrorContainer>
              </FormItemContainer>
              <FormItemContainer>
                <FormItemLabel>Отчество</FormItemLabel>
                <Controller
                  name="surname"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      {...field}
                      inputSize="md"
                      placeholder="Введите отчество (если есть)"
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.surname && <p>{errors.surname.message}</p>}
                </FieldErrorContainer>
              </FormItemContainer>
              <FormItemContainer>
                <FormItemLabel>Телефон</FormItemLabel>
                <Controller
                  name="mobile"
                  control={control}
                  render={({ field, fieldState }) => (
                    <MaskedPhoneNumberInput
                      {...field}
                      //  inputSize="md"
                      mask="+7 (___) ___-__-__"
                      replacement={{ _: /\d/ }}
                      placeholder={'Введите номер телефона'}
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.mobile && <p>{errors.mobile.message}</p>}
                </FieldErrorContainer>
              </FormItemContainer>
              {isEditingFormType ? (
                <FormItemContainer>
                  <Controller
                    name="isActive"
                    control={control}
                    render={({ field, fieldState }) => (
                      <p>
                        <StyledCheckbox {...field} checked={isActive} />
                        Действующий водитель
                      </p>
                    )}
                  />
                </FormItemContainer>
              ) : null}
            </FormColumn>
            <FormColumn>
              <FormItemContainer>
                <FormItemLabel>Табельный номер</FormItemLabel>
                <Controller
                  name="employeeNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      {...field}
                      inputSize="md"
                      placeholder="Введите табельный номер"
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.employeeNumber && (
                    <p>{errors.employeeNumber.message}</p>
                  )}
                </FieldErrorContainer>
              </FormItemContainer>
              <FormItemContainer>
                <FormItemLabel>Личный номер</FormItemLabel>
                <Controller
                  name="personalNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      {...field}
                      inputSize="md"
                      placeholder="Введите личный номер"
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.personalNumber && (
                    <p>{errors.personalNumber.message}</p>
                  )}
                </FieldErrorContainer>
              </FormItemContainer>
              <FormItemContainer>
                <FormItemLabel>Водительское удостоверение</FormItemLabel>
                <Controller
                  name="drivingLicense"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      {...field}
                      inputSize="md"
                      placeholder="Введите номер ВУ"
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.drivingLicense && (
                    <p>{errors.drivingLicense.message}</p>
                  )}
                </FieldErrorContainer>
              </FormItemContainer>
              <FormItemContainer>
                <FormItemLabel>Срок действия вод. удостоверения</FormItemLabel>
                <Controller
                  name="licenseExpirationDate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomDatetimepicker
                      {...field}
                      offShowTime
                      showTime={false}
                      value={licenseExpirationDate}
                      customFormat="DD.MM.YY"
                      allowClear={false}
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.licenseExpirationDate && (
                    <p>{errors.licenseExpirationDate.message}</p>
                  )}
                </FieldErrorContainer>
              </FormItemContainer>
            </FormColumn>
            <FormColumn>
              <FormItemContainer>
                <FormItemLabel>Парк</FormItemLabel>
                <Controller
                  name="parkId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomSelect
                      {...field}
                      options={parks.map((el) => ({
                        value: el.id,
                        label: el.name,
                      }))}
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.parkId && <p>{errors.parkId.message}</p>}
                </FieldErrorContainer>
              </FormItemContainer>
            </FormColumn>
          </FormLayout>
          <FormButtonsContainer>
            {deleteButton}
            <UiButton onClick={handleReset} variant="outline">
              Сбросить
            </UiButton>
            <UiButton variant="primary" type="submit">
              {submitButtonLabel}
            </UiButton>
          </FormButtonsContainer>
        </form>
      </ConfigProvider>
    </Spin>
  );
};
