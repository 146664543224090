import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';

import { EDIT_SUCCESS, EDIT_ERROR } from '../../consts';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';
import { ConnectedTrackerForm } from '@entities/tracker/ui/connected-form';
import { useUpdateTrackerMutation } from '@shared/api/services/tracker/enhanced';
import { DeleteTrackerButton } from '@features/trackers/delete/ui/button';
import {
  DriverOutput,
  UpdateDriverApiDriversPatchApiArg,
} from '@shared/api/services/drivers/api';
import { ConnectedDriverForm } from '@entities/driver/ui/connected-form';
import { useUpdateDriver } from '@shared/api/services/drivers/enhanced';
import { EditingFormSchemaType } from '@entities/driver/consts/schema';

type Props = {
  driver: DriverOutput;
  closeModal: () => void;
};

export function EditDriver({ driver, closeModal }: Props) {
  const [update, { isLoading, isSuccess, error }] = useUpdateDriver();

  const handleSubmit = async (data: Partial<EditingFormSchemaType>) => {
    try {
      const payload: UpdateDriverApiDriversPatchApiArg = {
        id: driver.id,
        driverUpdate: {
          full_name: `${data.lastName} ${data.firstName} ${data.surname}`,
          mobile: data.mobile,
          personal_number: data.personalNumber,
          employee_number: data.employeeNumber,
          driving_license: data.drivingLicense,
          license_expiration_date:
            data.licenseExpirationDate?.format('YYYY-MM-DD'),
          is_active: data.isActive,
          park_id: data.parkId,
        },
      };

      await update(payload).unwrap();

      openSuccessNotification(`${EDIT_SUCCESS}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.detail : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <ConnectedDriverForm
      submitButtonLabel="Применить"
      handleFormSubmit={handleSubmit}
      driver={driver}
      loading={isLoading}
      formType="editing"
    />
  );
}
