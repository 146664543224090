import { useSearchParams } from 'react-router-dom';
import { countPageSize } from '@shared/ui/table/lib/count-page-size';
import { ReleasesTableInstance } from '../table';
import { useData } from '../../hooks/use-data';

export function ConnectedReleasesTable() {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultPageSize = countPageSize();

  const { tableData, isLoading, isSuccess, isFetching, error } = useData();

  if (isLoading) {
    return <div>Идет загрузка....</div>;
  }

  if (error) {
    return (
      <>
        <div>Ошибка: не удалось загрузить данные по нарядам</div>
        <p>{JSON.stringify(error)}</p>
      </>
    );
  }

  if (isSuccess) {
    return (
      <ReleasesTableInstance
        tableData={tableData}
        fetching={isFetching && !isLoading}
        count={tableData.length === 0 ? 1 : tableData.length}
        defaultPageSize={defaultPageSize}
      />
    );
  }

  return <>Не удалось загрузить данные</>;
}
