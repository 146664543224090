import {
  useGetGroupsQuery,
  useGetTrackersModelsQuery,
} from '@shared/api/services/common/enhanced';
import { FormSchemaType } from '@entities/tracker/consts/schema';
import { DriverForm } from '../form';
import { DriverOutput } from '@shared/api/services/drivers/api';
import {
  CreatingFormSchemaType,
  EditingFormSchemaType,
} from '@entities/user/consts/schemas';

type Props = {
  handleFormSubmit: (
    data: Partial<EditingFormSchemaType> | CreatingFormSchemaType
  ) => Promise<void>;
  submitButtonLabel: string;
  driver?: DriverOutput;
  loading: boolean;
  deleteButton?: JSX.Element;
  formType: 'creating' | 'editing';
};

export function ConnectedDriverForm(props: Props) {
  const {
    data: parks = [],
    isLoading: isParksLoading,
    isFetching: isParksFetching,
    isSuccess: isParksSuccess,
    error: parksError,
  } = useGetGroupsQuery(undefined);

  if (isParksLoading) {
    return <div>Идет загрузка....</div>;
  }

  const error = [parksError].find((err) => err !== undefined);

  if (error) {
    return <div>Ошибка</div>;
  }

  if (isParksSuccess) {
    return <DriverForm parks={parks} {...props} />;
  }

  return null;
}
