import { useAppSelector } from '@app/hooks';
import { DriverOutputTable } from '@entities/driver/consts/types';
import { getParkNameByParkId } from '@entities/vehicle/lib/get-park-name-by-group-id';
import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { useGetDrivers } from '@shared/api/services/drivers/enhanced';
import { selectDriversFilters } from '@shared/redux/slices/drivers/filters';
import { useMemo } from 'react';

export const useData = () => {
  const { parkIds } = useAppSelector(selectDriversFilters);

  const {
    data: drivers,
    isLoading: isDriversLoading,
    isFetching: isDriversFetching,
    isSuccess: isDriversSuccess,
    error: driversError,
  } = useGetDrivers(parkIds.length ? parkIds.join(',') : '');

  const {
    data: parks = [],
    isLoading: isParksLoading,
    isFetching: isParksFetching,
    isSuccess: isParksSuccess,
    error: parksError,
  } = useGetGroupsQuery(undefined);

  const error = [driversError, parksError].find((err) => err !== undefined);

  const isLoading = isDriversLoading || isParksLoading;

  const isSuccess = isDriversSuccess && isParksSuccess;

  const isFetching = isDriversFetching || isParksFetching;

  const tableData: DriverOutputTable[] = useMemo(() => {
    if (!drivers || !parks) {
      return [];
    }

    return drivers
      .map((driver) => ({
        ...driver,
        park_name: getParkNameByParkId(driver.park_id, parks),
      }))
      .sort((a, b) => b.id - a.id);
  }, [drivers, parks]);

  return {
    tableData,
    isLoading,
    isSuccess,
    isFetching,
    error,
  };
};
