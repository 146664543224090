import { FiltersButton } from '@features/drivers/filters/ui';
import { HeaderLayout } from '@widgets/header/layout';
import { ContentWrapper, Outer, Title } from '@widgets/header/consts/styles';
import { AddDriverButton } from '@features/drivers/add/ui/add-driver-button';
import { UploadDriverButton } from '@features/drivers/upload-drivers/ui/button';

export function DriversHeader() {
  const content = (
    <ContentWrapper>
      <Title>Водители</Title>
      <FiltersButton />
      <AddDriverButton />
      <UploadDriverButton />
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
}
