import { HeaderLayout } from '@widgets/header/layout';
import { ContentWrapper, Outer, Title } from '@widgets/header/consts/styles';
import { UploadReleasesButton } from '@features/releases/upload-releases/ui/button';
import { FilterForm } from '@features/releases/filters/ui/form';

export function ReleasesHeader() {
  const content = (
    <ContentWrapper>
      <Title>Наряды</Title>
      <UploadReleasesButton />
      <FilterForm />
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
}
