import dayjs from 'dayjs';
import { FC } from 'react';

type Props = {
  isActive?: boolean | null;
  expirationDate?: string | null;
};

export const ExpirationDate: FC<Props> = ({ expirationDate, isActive }) => {
  if (expirationDate === undefined || expirationDate === null)
    return <p>Нет данных</p>;

  const dayJsExpirationDate = dayjs(expirationDate);
  const formattedDate = dayJsExpirationDate.format('DD.MM.YYYY');

  if (isActive === undefined || isActive === null)
    return <p>До: {dayJsExpirationDate.format('DD.MM.YYYY')}</p>;

  const todayDate = dayjs();

  const difference = dayJsExpirationDate.diff(todayDate, 'day');
  const isOver = difference < 0;
  const isGoingToBeOver = difference >= 0 && difference < 25;
  const isExpirationDistant = difference >= 25;
  const isOverText = isOver ? 'Истёк:' : 'До:';

  if (!isActive) {
    return (
      <p style={{ color: '#666E7E' }}>
        {isOverText} {formattedDate}
      </p>
    );
  } else {
    if (isExpirationDistant) {
      return (
        <p style={{ color: '#1FC48B' }}>
          {isOverText} {formattedDate}
        </p>
      );
    }
    if (isGoingToBeOver) {
      return (
        <p style={{ color: '#FF9A24' }}>
          {isOverText} {formattedDate}
        </p>
      );
    }
    if (isOver) {
      return (
        <p style={{ color: '#FE4D4D' }}>
          {isOverText} {formattedDate}
        </p>
      );
    }
  }

  return <p>До: {dayJsExpirationDate.format('DD.MM.YYYY')}</p>;
};
