import DownCircleTwoTone from '@ant-design/icons/lib/icons/DownCircleTwoTone';
import UpCircleTwoTone from '@ant-design/icons/lib/icons/UpCircleTwoTone';
import { FC } from 'react';
import styled from 'styled-components';

const BatteryErrorsPanelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ErrorsList = styled.div<{ isSingleChange: boolean }>`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-left: ${({ isSingleChange }) => (isSingleChange ? '19px' : '5px')};
`;

const IconWrapper = styled.div`
  width: 20px;
  align-self: center;
`;

type Props = {
  isSingle: boolean;
  isExpand: boolean;
  result: JSX.Element[];
  toggleExpand: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ExpandingCell: FC<Props> = ({
  isSingle,
  isExpand,
  result,
  toggleExpand,
}) => {
  return (
    <BatteryErrorsPanelWrapper>
      {isSingle ? null : isExpand ? (
        <IconWrapper>
          <UpCircleTwoTone width={20} onClick={() => toggleExpand(false)} />
        </IconWrapper>
      ) : (
        <IconWrapper>
          <DownCircleTwoTone width={20} onClick={() => toggleExpand(true)} />
        </IconWrapper>
      )}
      <ErrorsList isSingleChange={isSingle}>
        {isExpand ? result : result[0]}
      </ErrorsList>
    </BatteryErrorsPanelWrapper>
  );
};
