import { FC, useMemo } from 'react';
import { useForm, Controller, type FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppDispatch, useAppSelector } from '@app/hooks';

import {
  selectDriversFilters,
  setFilters,
} from '@shared/redux/slices/drivers/filters';
import { resetPageByFiltering } from '@shared/utils/table/reset-page-by-filtering';
import { CustomSelectMultiple } from '@shared/ui/custom-select-new';

import { FormSchema, FormSchemaType } from '../../consts/schema';
import { UiButton } from '@shared/ui/ui-button';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';

import { FormButtonsContainer, FormLayout } from './styles';
import { useData } from '../../hooks/use-data';

type Props = {
  closeModal: () => void;
};

export const FiltersForm: FC<Props> = ({ closeModal }) => {
  const filters = useAppSelector(selectDriversFilters);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, error, parks } = useData();

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: filters,
  });

  const onSubmit = async (data: FieldValues) => {
    resetPageByFiltering(searchParams, setSearchParams);
    dispatch(setFilters(data));

    closeModal();
  };

  const handleReset = () => {
    setValue('parkIds', []);
  };

  const parksOptions = useMemo(() => {
    if (!parks) return [];
    if (parks) {
      return parks.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }
  }, [parks]);

  if (isLoading) {
    return <>Загрузка...</>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="password">
      <FormLayout>
        <FormColumn>
          <FormItemContainer>
            <FormItemLabel>По парку</FormItemLabel>
            <Controller
              name="parkIds"
              control={control}
              render={({ field, fieldState }) => (
                <CustomSelectMultiple {...field} options={parksOptions} />
              )}
            />
            <FieldErrorContainer>
              {errors?.parkIds && <p>{errors.parkIds.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>
        </FormColumn>
      </FormLayout>
      <FormButtonsContainer>
        <UiButton variant="outline" onClick={handleReset} type="button">
          Сбросить
        </UiButton>
        <UiButton variant="primary" type="submit">
          Применить
        </UiButton>
      </FormButtonsContainer>
    </form>
  );
};
