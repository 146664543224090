import { Radio, RadioChangeEvent, Space } from 'antd';
import { HeaderLayout } from '../../layout';
import { UiRadioButton } from '@shared/ui/ui-radio-button';
import { FC, useState } from 'react';
import { TableOfTelemetryType } from '@features/telemetry/table/consts/types';

import { ContentWrapper, Outer, Title } from '../../consts/styles';
import {
  TelemetryTableTypeWrapper,
  TelemetryTableTypeBlockTitle,
} from './styles';

type Props = {
  selectedType: TableOfTelemetryType;
  selectType: React.Dispatch<React.SetStateAction<TableOfTelemetryType>>;
};

export const TelemetryHeader: FC<Props> = ({ selectType, selectedType }) => {
  const onChangeSelectedType = (e: RadioChangeEvent) => {
    selectType(e.target.value);
  };

  const content = (
    <ContentWrapper>
      <Title>Телеметрия</Title>
      <TelemetryTableTypeWrapper>
        <TelemetryTableTypeBlockTitle>
          Тип таблицы:
        </TelemetryTableTypeBlockTitle>
        <Radio.Group
          style={{ width: '250px', paddingLeft: '30px' }}
          onChange={onChangeSelectedType}
          value={selectedType}
        >
          <Space direction="horizontal">
            <UiRadioButton
              style={{ fontWeight: '600', fontSize: '17px' }}
              value="vehicle"
              label="По ТС"
            />
            <UiRadioButton
              style={{ fontWeight: '600', fontSize: '17px' }}
              value="battery"
              label="По батареям"
            />
          </Space>
        </Radio.Group>
      </TelemetryTableTypeWrapper>
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
};
