import { countPageSize } from '@shared/ui/table/lib/count-page-size';
import { DriversTableInstance } from '../table';
import { useData } from '../../hooks/use-data';

export function ConnectedDriversTable() {
  const defaultPageSize = countPageSize();

  const { tableData, isLoading, isFetching, isSuccess, error } = useData();

  if (isLoading) {
    return <div>Идет загрузка....</div>;
  }

  if (error) {
    return <div>Ошибка: не удалось загрузить данные по водителям</div>;
  }

  if (isSuccess) {
    return (
      <DriversTableInstance
        tableData={tableData}
        fetching={isFetching && !isLoading}
        count={tableData.length}
        defaultPageSize={defaultPageSize}
      />
    );
  }

  return <>Не удалось загрузить данные</>;
}
