import { useMemo } from 'react';
import { BatteryTelemetryErrorRedisModel } from '@shared/api/services/telemetry/api';
import { VehiclesTelemetryData } from '../consts/types';
import { useElectricbusesData } from '@entities/vehicle/hooks/use-electricbuses-data';

export const useData = () => {
  const { tableData, isLoading, isSuccess, error } = useElectricbusesData({
    groupIds: [],
    pollingIntervals: {
      electricbuses: 5000,
      trackers: 5000,
      telemetry: 5000,
    },
  });

  const electricbuses = tableData;

  const vehiclesTelemetryTableData: VehiclesTelemetryData = useMemo(() => {
    if (electricbuses === undefined || electricbuses === null) {
      return [];
    }

    return electricbuses
      .filter((electrobusItem) => electrobusItem.imei !== undefined)
      .map((electrobusItem) => {
        const trackerImei = electrobusItem.imei;
        const currentTelemetry = electrobusItem.telemetry;
        const park_name = electrobusItem.park_name;

        const batteries = electrobusItem.telemetry?.battery_telemetry ?? [];

        const errors =
          electrobusItem.telemetry?.battery_telemetry?.reduce((acc, curr) => {
            if (curr.errors === undefined) {
              return acc;
            } else {
              return [...acc, ...curr.errors];
            }
          }, [] as BatteryTelemetryErrorRedisModel[]) ?? [];

        return {
          park_name: park_name,
          imei: trackerImei!,
          grz: electrobusItem.grn,
          garage_number: electrobusItem.garage_number,
          time: currentTelemetry?.time,
          coordinates: {
            latitude: currentTelemetry?.latitude,
            longitude: currentTelemetry?.longitude,
          },
          mileage: electrobusItem.mileage,
          firmware: electrobusItem.firmware,
          soc: currentTelemetry?.soc,
          soh: currentTelemetry?.soh,
          charge_current_A: currentTelemetry?.charge_current_A,
          discharge_current_A: currentTelemetry?.discharge_current_A,
          permissible_charge_current:
            currentTelemetry?.permissible_charge_current,
          permissible_discharge_current:
            currentTelemetry?.permissible_discharge_current,
          power_reserve: currentTelemetry?.power_reserve,
          last_charge: currentTelemetry?.last_charge,
          ctts: currentTelemetry?.ctts,
          interval_telemetry_S: currentTelemetry?.interval_telemetry_S,
          count_charge_session: currentTelemetry?.count_charge_session,
          errors,
          batteries,
        };
      })
      .sort((a, b) => Number(a.imei) - Number(b.imei));
  }, [electricbuses]);

  const batteriesTelemetryTableData = useMemo(() => {
    const result = vehiclesTelemetryTableData.reduce((acc, curr) => {
      const formatBatteries = curr.batteries.map((battery) => ({
        ...battery,
        grz: curr.grz,
        garage_number: curr.garage_number,
        imei: curr.imei,
      }));
      return [...acc, ...formatBatteries];
    }, []);
    return result;
  }, [vehiclesTelemetryTableData]);

  return {
    vehiclesTelemetryTableData,
    batteriesTelemetryTableData,
    isLoading,
    error,
    isSuccess,
  };
};
