import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

const dayjsType = z.custom<Dayjs>((data) => dayjs.isDayjs(data), {
  message: 'Необходимо выбрать дату',
});

export const CreatingSchema = z.object({
  firstName: z
    .string({
      required_error: 'Поле обязательно к заполнению',
    })
    .min(1, { message: 'Поле обязательно к заполнению' }),
  lastName: z
    .string({
      required_error: 'Поле обязательно к заполнению',
    })
    .min(1, { message: 'Поле обязательно к заполнению' }),
  surname: z.string().optional(),
  mobile: z
    .string({
      required_error: 'Поле обязательно к заполнению',
    })
    .regex(
      /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      'Введите номер телефона в формате +7 (XXX) XXX-XX-XX'
    ),
  personalNumber: z
    .string({
      required_error: 'Поле обязательно к заполнению',
    })
    .min(1, { message: 'Поле обязательно к заполнению' }),
  employeeNumber: z
    .string({
      required_error: 'Поле обязательно к заполнению',
    })
    .min(1, { message: 'Поле обязательно к заполнению' }),
  drivingLicense: z
    .string({
      required_error: 'Поле обязательно к заполнению',
    })
    .min(1, { message: 'Поле обязательно к заполнению' }),
  parkId: z.number({ required_error: 'Выберите парк' }),
  licenseExpirationDate: dayjsType,
});

export const EditingSchema = z.object({
  firstName: z
    .string()
    .refine((val) => val.trim().length > 0, {
      message: 'Поле обязательно к заполнению',
    })
    .optional(),
  lastName: z.string().refine((val) => val.trim().length > 0, {
    message: 'Поле обязательно к заполнению',
  }),
  surname: z.string().optional(),
  mobile: z
    .string()
    .regex(
      /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      'Введите номер телефона в формате +7 (XXX) XXX-XX-XX'
    ),
  personalNumber: z.string().refine((val) => val.trim().length > 0, {
    message: 'Поле обязательно к заполнению',
  }),
  employeeNumber: z.string().refine((val) => val.trim().length > 0, {
    message: 'Поле обязательно к заполнению',
  }),
  drivingLicense: z.string().refine((val) => val.trim().length > 0, {
    message: 'Поле обязательно к заполнению',
  }),
  licenseExpirationDate: dayjsType,
  isActive: z.boolean(),
  parkId: z.number({ required_error: 'Выберите парк' }),
});

export type CreatingFormSchemaType = z.infer<typeof CreatingSchema>;
export type EditingFormSchemaType = z.infer<typeof EditingSchema>;
