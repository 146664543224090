import { ElectrobusPlacemarkBig } from '../electrobus-placemark-big';
import { ElectrobusPlacemarkSmall } from '../electrobus-placemark-small';

const ZOOM_LEVEL_TRESHOLD = 12;

type Props = {
  selected: boolean;
  soc: number;
  zoomLevel: number;
  imei: string;
  latitude: number;
  longitude: number;
  preLatitude: number;
  preLongitude: number;
  garageNumber?: number;
};

export function ElectrobusPlacemark(props: Props) {
  const { zoomLevel, ...rest } = props;

  if (zoomLevel > ZOOM_LEVEL_TRESHOLD) {
    return <ElectrobusPlacemarkBig {...rest} />;
  }

  return <ElectrobusPlacemarkSmall {...rest} />;
}
