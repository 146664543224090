import { LogsHeader } from '@widgets/header/ui/logs';
import { ConnectedLogsTable } from '@features/logs/table/ui/connected-table';
import { StandardPageLayout } from '../styles';
import { useRole } from '@entities/user/lib/hooks/use-role';

export function LogsPage() {
  const { isAdmin } = useRole();

  if (!isAdmin) {
    return <>Для просмотра страницы необходимы права администратора</>;
  }

  return (
    <StandardPageLayout>
      <LogsHeader />
      <ConnectedLogsTable />
    </StandardPageLayout>
  );
}
