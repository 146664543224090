import { FC } from 'react';

type Props = {
  isActive?: boolean | null;
};

export const DriverActivity: FC<Props> = ({ isActive }) => {
  if (isActive === null || isActive === undefined) return <p>Нет данных</p>;

  return isActive ? (
    <p style={{ color: '#1FC48B' }}>Актуальный</p>
  ) : (
    <p style={{ color: '#666E7E' }}>Архив</p>
  );
};
