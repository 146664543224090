import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadDriversApiDriversUploadDriversPost: build.mutation<
      UploadDriversApiDriversUploadDriversPostApiResponse,
      UploadDriversApiDriversUploadDriversPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/drivers/upload_drivers/`,
        method: 'POST',
        body: queryArg.bodyUploadDriversApiDriversUploadDriversPost,
        params: { park_id: queryArg.parkId },
      }),
    }),
    createDriverApiDriversPost: build.mutation<
      CreateDriverApiDriversPostApiResponse,
      CreateDriverApiDriversPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/drivers/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getDriversApiDriversGet: build.query<
      GetDriversApiDriversGetApiResponse,
      GetDriversApiDriversGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/drivers/`,
        params: { park_ids: queryArg },
      }),
    }),
    updateDriverApiDriversPatch: build.mutation<
      UpdateDriverApiDriversPatchApiResponse,
      UpdateDriverApiDriversPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/drivers/`,
        method: 'PATCH',
        body: queryArg.driverUpdate,
        params: { driver_id: queryArg.driverId },
      }),
    }),
    uploadDailyReleasesApiDailyReleasesUploadDailyReleasesPost: build.mutation<
      UploadDailyReleasesApiDailyReleasesUploadDailyReleasesPostApiResponse,
      UploadDailyReleasesApiDailyReleasesUploadDailyReleasesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/daily_releases/upload_daily_releases/`,
        method: 'POST',
        body: queryArg.bodyUploadDailyReleasesApiDailyReleasesUploadDailyReleasesPost,
        params: { park_id: queryArg.parkId },
      }),
    }),
    getDailyReleasesApiDailyReleasesGet: build.query<
      GetDailyReleasesApiDailyReleasesGetApiResponse,
      GetDailyReleasesApiDailyReleasesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/daily_releases/`,
        params: {
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          park_ids: queryArg.parkIds,
        },
      }),
    }),
    getDailyReleasesWithDriversApiDailyReleasesWithDriverGet: build.query<
      GetDailyReleasesWithDriversApiDailyReleasesWithDriverGetApiResponse,
      GetDailyReleasesWithDriversApiDailyReleasesWithDriverGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/daily_releases/with_driver`,
        params: {
          garage_number: queryArg.garageNumber,
          park_id: queryArg.parkId,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UploadDriversApiDriversUploadDriversPostApiResponse =
  /** status 200 Successful Response */ any;
export type UploadDriversApiDriversUploadDriversPostApiArg = {
  parkId: number;
  bodyUploadDriversApiDriversUploadDriversPost: BodyUploadDriversApiDriversUploadDriversPost;
};
export type CreateDriverApiDriversPostApiResponse =
  /** status 200 Successful Response */ DriverOutput;
export type CreateDriverApiDriversPostApiArg = DriverCreate;
export type GetDriversApiDriversGetApiResponse =
  /** status 200 Successful Response */ DriverOutput[];
export type GetDriversApiDriversGetApiArg = /** List IDs group */ string;
export type UpdateDriverApiDriversPatchApiResponse =
  /** status 200 Successful Response */ any;
export type UpdateDriverApiDriversPatchApiArg = {
  driverId: number;
  driverUpdate: DriverUpdate;
};
export type UploadDailyReleasesApiDailyReleasesUploadDailyReleasesPostApiResponse =
  /** status 200 Successful Response */ any;
export type UploadDailyReleasesApiDailyReleasesUploadDailyReleasesPostApiArg = {
  parkId: number;
  bodyUploadDailyReleasesApiDailyReleasesUploadDailyReleasesPost: BodyUploadDailyReleasesApiDailyReleasesUploadDailyReleasesPost;
};
export type GetDailyReleasesApiDailyReleasesGetApiResponse =
  /** status 200 Successful Response */ DailyReleaseOutputWithDriver[];
export type GetDailyReleasesApiDailyReleasesGetApiArg = {
  dateFrom: string;
  dateTo: string;
  /** List IDs group */
  parkIds?: string;
};
export type GetDailyReleasesWithDriversApiDailyReleasesWithDriverGetApiResponse =
  /** status 200 Successful Response */ DailyReleaseAndDriverOutput;
export type GetDailyReleasesWithDriversApiDailyReleasesWithDriverGetApiArg = {
  garageNumber: number;
  parkId: number;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type BodyUploadDriversApiDriversUploadDriversPost = {
  file: Blob;
};
export type DriverOutput = {
  full_name: string;
  mobile: string;
  personal_number: string;
  employee_number: string;
  driving_license: string;
  license_expiration_date: string | null;
  park_id: number;
  id: number;
  is_active: boolean;
};
export type DriverCreate = {
  full_name: string;
  mobile: string;
  personal_number: string;
  employee_number: string;
  driving_license: string;
  license_expiration_date: string | null;
  park_id: number;
};
export type DriverUpdate = {
  full_name?: string | null;
  mobile?: string | null;
  personal_number?: string | null;
  employee_number?: string | null;
  driving_license?: string | null;
  license_expiration_date?: string | null;
  park_id?: number | null;
  is_active?: boolean | null;
};
export type BodyUploadDailyReleasesApiDailyReleasesUploadDailyReleasesPost = {
  file: Blob;
};
export type DailyReleaseOutputWithDriver = {
  id: number;
  driver_id: number;
  park_id: number;
  date: string;
  garage_number: number | null;
  route: string | null;
  order_number: number;
  start_time: string;
  end_time: string;
  full_name: string;
  employee_number: string;
};
export type DailyReleaseAndDriverOutput = {
  id: number;
  driver_id: number;
  park_id: number;
  date: string;
  garage_number: number | null;
  route: string | null;
  order_number: number;
  start_time: string;
  end_time: string;
  driver: DriverOutput;
};
export const {
  useUploadDriversApiDriversUploadDriversPostMutation,
  useCreateDriverApiDriversPostMutation,
  useGetDriversApiDriversGetQuery,
  useUpdateDriverApiDriversPatchMutation,
  useUploadDailyReleasesApiDailyReleasesUploadDailyReleasesPostMutation,
  useGetDailyReleasesApiDailyReleasesGetQuery,
  useGetDailyReleasesWithDriversApiDailyReleasesWithDriverGetQuery,
} = injectedRtkApi;
