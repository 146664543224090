import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';

import { QUERY_PARAM } from '@shared/consts/server';
import { usePaginationState, useSortingState } from '@shared/ui/table/hooks';
import { ServerTableLayout } from '@shared/ui/table/layout';
import { useColumns } from '../../hooks/use-columns';
import { useSortDriversData } from '../../hooks/use-sort-drivers-data';
import { DriverOutputTable } from '@entities/driver/consts/types';
type Props = {
  count: number | undefined;
  fetching: boolean;
  tableData: DriverOutputTable[];
  defaultPageSize: number;
};

export const DriversTableInstance: FC<Props> = ({
  count,
  fetching,
  tableData,
  defaultPageSize,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const columns = useColumns();

  const size = searchParams.get(QUERY_PARAM.limit);
  const sorting = useSortingState();
  const pagination = usePaginationState();
  const page = searchParams.get(QUERY_PARAM.page);
  const sortedField = searchParams.get(QUERY_PARAM.ordering) as
    | keyof DriverOutputTable
    | null;
  const sortedBy = searchParams.get(QUERY_PARAM.type);

  const sortedData = useSortDriversData({ tableData, sortedBy, sortedField });

  const paginatedData = useMemo(() => {
    const currentPage = Number(page ?? 1);
    const currentSize = Number(size ?? defaultPageSize);

    const startIdx = (currentPage - 1) * currentSize;
    const endIdx = startIdx + currentSize;
    return sortedData.slice(startIdx, endIdx);
  }, [sortedData, size, page]);

  const table = useReactTable({
    data: paginatedData,
    columns,
    state: {
      sorting,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (size ? Number(size) : defaultPageSize))
      : undefined,
  });

  return (
    <ServerTableLayout
      loading={fetching}
      table={table}
      rowsCount={count}
      defaultPageSize={defaultPageSize}
    />
  );
};
