import { useParams } from 'react-router-dom';

import { FiltersButton } from '@features/vehicle/filters';

import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { HeaderLayout } from '../../layout';
import { ContentWrapper, Title } from '../../consts/styles';
import { Outer } from './styles';

type Props = {
  showVehicleInfo: boolean;
};

export function ConvoyHeader({ showVehicleInfo }: Props) {
  const { eParkId, convoyId } = useParams();

  const { data, isLoading, error, isSuccess } = useGetGroupsQuery(undefined, {
    skip: false,
  });

  const park = data?.find(({ id }) => Number(id) === Number(eParkId));
  const convoy = park?.groups?.find(({ id }) => String(id) === convoyId);

  const title = `${park?.name ?? ''}, ${convoy?.name ?? ''}`;

  const content = (
    <ContentWrapper>
      <Title>{title}</Title>
      <FiltersButton />
    </ContentWrapper>
  );

  return (
    <Outer showVehicleInfo={showVehicleInfo}>
      <HeaderLayout content={content} isStadardHeader={false} />
    </Outer>
  );
}
