
  import { ReleasesHeader } from '@widgets/header/ui/releases';
  import { ConnectedReleasesTable } from '@features/releases/table/ui/connected-table';
  import { StandardPageLayout } from '../styles';

  export function ReleasesPage() {
    return (
      <StandardPageLayout>
        <ReleasesHeader />
        <ConnectedReleasesTable />
      </StandardPageLayout>
    );
  }
