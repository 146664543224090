import { InputMask } from '@react-input/mask';
import styled from 'styled-components';

export const FormLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 300px);
  column-gap: 80px;

  padding-bottom: 10px;
`;

export const FormButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 24px 0 40px 0;

  border-top: 1px solid rgba(163, 174, 196, 0.4);
`;

export const MaskedPhoneNumberInput = styled(InputMask)`
  width: 58px;
  border-radius: 12px;
  border: 2px solid #666e7e;
  padding: 8px 16px;
  background-color: transparent;
  color: #a3aec4;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &::placeholder {
    color: #a3aec4;
  }

  &.ant-input-disabled {
    color: #a3aec4;
  }

  &:hover {
    border: 2px solid #a3aec4;
    color: #f7faff;
    background-color: transparent;
  }

  &:focus {
    border: 2px solid #09fba8;
    color: #f7faff;
    outline: none;
    background-color: transparent;
  }
`;
