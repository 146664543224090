import { FC, useState } from 'react';
import {
  Button,
  ConfigProvider,
  GetProp,
  message,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useForm, Controller, type FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import locale from 'antd/locale/ru_RU';
import theme from 'antd/es/theme';
import { useAppDispatch, useAppSelector } from '@app/hooks';

import { selectLogsFilters } from '@shared/redux/slices/logs/filters';
import { CustomSelect } from '@shared/ui/custom-select-new';

import { FormSchema, FormSchemaType } from '../../consts';
import { UiButton } from '@shared/ui/ui-button';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';

import { FormButtonsContainer, FormLayout } from './styles';
import { useData } from '../../hooks/use-data';
import {
  useUploadDrivers,
  useUploadReleases,
} from '@shared/api/services/drivers/enhanced';
import { UploadOutlined } from '@ant-design/icons';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type Props = {
  closeModal: () => void;
};

export const UploadReleases: FC<Props> = ({ closeModal }) => {
  const [upload, { data, isLoading: isUploading }] = useUploadReleases();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const { parks, isLoading, error } = useData();

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
  });

  const parkId = watch('parkId');

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isXml = file.type === 'text/xml';
      if (!isXml) {
        message.error('Вы можете загрузить только файлы с форматом .xml');
        return Upload.LIST_IGNORE;
      } else {
        if (fileList.length === 0) {
          setFileList([...fileList, file]);
          return isXml;
        } else {
          message.error('Вы можете загрузить только один файл');
          return Upload.LIST_IGNORE;
        }
      }
    },
    fileList,
    maxCount: 1,
  };

  /*

  Водитель,
  табельный номер 
  
  */

  const onSubmit = async (data: FormSchemaType) => {
    const parkId = data.parkId!;

    const file = fileList[0];

    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await upload({
        parkId,
        bodyUploadDailyReleasesApiDailyReleasesUploadDailyReleasesPost:
          formData,
      });

      if (res?.error?.status === 400) {
        throw res?.error;
      }

      if (res?.data?.detail) {
        openSuccessNotification(`${res?.data?.detail}`);
      } else {
        openSuccessNotification(`Список нарядов обновлён`);
      }
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err?.data?.detail
        : 'Не удалось обновить список нарядов';

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Ошибка</div>;
  }

  return (
    <Spin spinning={isUploading}>
      <ConfigProvider
        locale={locale}
        theme={{
          algorithm: theme.darkAlgorithm,
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          autoComplete="password"
        >
          <FormLayout>
            <FormColumn>
              <FormItemContainer>
                <FormItemLabel>Парк</FormItemLabel>
                <Controller
                  name="parkId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomSelect
                      {...field}
                      options={parks.map((el) => ({
                        value: el.id,
                        label: el.name,
                      }))}
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.parkId && <p>{errors.parkId.message}</p>}
                </FieldErrorContainer>
              </FormItemContainer>
              {parkId !== undefined && (
                <FormItemContainer>
                  <FormItemLabel>Файл xml</FormItemLabel>
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Выбрать файл</Button>
                  </Upload>
                </FormItemContainer>
              )}
            </FormColumn>
          </FormLayout>
          <FormButtonsContainer>
            {(fileList.length !== 0 || uploading) && (
              <UiButton
                variant="primary"
                type="submit"
                // onClick={handleUpload}
                disabled={fileList.length === 0}
                style={{ marginTop: 16 }}
              >
                Загрузить наряды
              </UiButton>
            )}
          </FormButtonsContainer>
        </form>
      </ConfigProvider>
    </Spin>
  );
};
