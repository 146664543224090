import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    apiAuthTokenAuthCreate: build.mutation<
      ApiAuthTokenAuthCreateApiResponse,
      ApiAuthTokenAuthCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token-auth/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiAuthTokenRefreshCreate: build.mutation<
      ApiAuthTokenRefreshCreateApiResponse,
      ApiAuthTokenRefreshCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token-refresh/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiAuthTokenVerifyCreate: build.mutation<
      ApiAuthTokenVerifyCreateApiResponse,
      ApiAuthTokenVerifyCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token-verify/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiLoggerSystemList: build.query<
      ApiLoggerSystemListApiResponse,
      ApiLoggerSystemListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/logger_system/`,
        params: {
          actions: queryArg.actions,
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          service: queryArg.service,
          sorted_by: queryArg.sortedBy,
          sorted_field: queryArg.sortedField,
          user_ids: queryArg.userIds,
        },
      }),
    }),
    apiUsersDeleteDestroy: build.mutation<
      ApiUsersDeleteDestroyApiResponse,
      ApiUsersDeleteDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg}/delete/`,
        method: 'DELETE',
      }),
    }),
    apiUsersUpdatePartialUpdate: build.mutation<
      ApiUsersUpdatePartialUpdateApiResponse,
      ApiUsersUpdatePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.username}/update/`,
        method: 'PATCH',
        body: queryArg.patchedUserUpdate,
      }),
    }),
    apiUsersCreateCreate: build.mutation<
      ApiUsersCreateCreateApiResponse,
      ApiUsersCreateCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/create/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiUsersListList: build.query<
      ApiUsersListListApiResponse,
      ApiUsersListListApiArg
    >({
      query: () => ({ url: `/api/users/list/` }),
    }),
    apiUsersSelfRetrieve: build.query<
      ApiUsersSelfRetrieveApiResponse,
      ApiUsersSelfRetrieveApiArg
    >({
      query: () => ({ url: `/api/users/self` }),
    }),
    apiUsersSelfPartialUpdate: build.mutation<
      ApiUsersSelfPartialUpdateApiResponse,
      ApiUsersSelfPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/self`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ApiAuthTokenAuthCreateApiResponse = /** status 200  */ JwtUser;
export type ApiAuthTokenAuthCreateApiArg = JwtUserWrite;
export type ApiAuthTokenRefreshCreateApiResponse =
  /** status 200  */ TokenRefreshRead;
export type ApiAuthTokenRefreshCreateApiArg = TokenRefreshWrite;
export type ApiAuthTokenVerifyCreateApiResponse =
  /** status 200  */ TokenVerify;
export type ApiAuthTokenVerifyCreateApiArg = TokenVerifyWrite;
export type ApiLoggerSystemListApiResponse =
  /** status 200  */ PaginatedLogEntryOutputListRead;
export type ApiLoggerSystemListApiArg = {
  /** {0: 'create', 1: 'update', 2: 'delete'} */
  actions?: number[];
  dateFrom?: string;
  dateTo?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** * `electricbus` - electricbus
   * `tracker` - tracker
   * `user` - user */
  service?: 'electricbus' | 'tracker' | 'user';
  /** * `asc` - asc
   * `desc` - desc */
  sortedBy?: 'asc' | 'desc';
  /** * `datetime` - datetime
   * `user` - user
   * `action` - action */
  sortedField?: 'datetime' | 'user' | 'action';
  userIds?: number[];
};
export type ApiUsersDeleteDestroyApiResponse = unknown;
export type ApiUsersDeleteDestroyApiArg = string;
export type ApiUsersUpdatePartialUpdateApiResponse =
  /** status 200  */ UserUpdate;
export type ApiUsersUpdatePartialUpdateApiArg = {
  username: string;
  patchedUserUpdate: PatchedUserUpdateWrite;
};
export type ApiUsersCreateCreateApiResponse = /** status 200  */ UserCreate;
export type ApiUsersCreateCreateApiArg = UserCreateWrite;
export type ApiUsersListListApiResponse = /** status 200  */ UserBaseInfoRead[];
export type ApiUsersListListApiArg = void;
export type ApiUsersSelfRetrieveApiResponse =
  /** status 200  */ UserBaseInfoRead;
export type ApiUsersSelfRetrieveApiArg = void;
export type ApiUsersSelfPartialUpdateApiResponse =
  /** status 200  */ UserBaseInfoRead;
export type ApiUsersSelfPartialUpdateApiArg = PatchedUserBaseInfo;
export type JwtUser = {};
export type JwtUserWrite = {
  username: string;
  password: string;
};
export type TokenRefresh = {};
export type TokenRefreshRead = {
  access: string;
};
export type TokenRefreshWrite = {
  refresh: string;
};
export type TokenVerify = {};
export type TokenVerifyWrite = {
  token: string;
};
export type ActionEnum = 0 | 1 | 2 | 3;
export type UserBaseInfo = {
  first_name?: string;
  last_name?: string;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  surname?: string | null;
  /** Designates whether the user can log into this admin site. */
  is_staff?: boolean;
  counterparty_id?: number | null;
  available_park_ids?: number[] | null;
};
export type UserBaseInfoRead = {
  id: number;
  first_name?: string;
  last_name?: string;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  surname?: string | null;
  role_id: number | null;
  /** Designates whether the user can log into this admin site. */
  is_staff?: boolean;
  counterparty_id?: number | null;
  available_park_ids?: number[] | null;
};
export type LogEntryOutput = {
  action: ActionEnum;
  timestamp?: string;
  object_repr: string;
  changes?: any | null;
  actor: UserBaseInfo;
};
export type LogEntryOutputRead = {
  id: number;
  action: ActionEnum;
  timestamp?: string;
  object_repr: string;
  changes?: any | null;
  actor: UserBaseInfoRead;
};
export type PaginatedLogEntryOutputList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: LogEntryOutput[];
};
export type PaginatedLogEntryOutputListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: LogEntryOutputRead[];
};
export type UserUpdate = {
  first_name?: string;
  last_name?: string;
  surname?: string;
  available_park_ids?: number[];
  role_id?: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type UserUpdateWrite = {
  password?: string;
  first_name?: string;
  last_name?: string;
  new_username?: string;
  surname?: string;
  available_park_ids?: number[];
  role_id?: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type PatchedUserUpdate = {
  first_name?: string;
  last_name?: string;
  surname?: string;
  available_park_ids?: number[];
  role_id?: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type PatchedUserUpdateWrite = {
  password?: string;
  first_name?: string;
  last_name?: string;
  new_username?: string;
  surname?: string;
  available_park_ids?: number[];
  role_id?: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type UserCreate = {
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  first_name: string;
  last_name: string;
  surname?: string;
  available_park_ids: number[];
  role_id: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type UserCreateWrite = {
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  surname?: string;
  available_park_ids: number[];
  role_id: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type PatchedUserBaseInfo = {
  first_name?: string;
  last_name?: string;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: string;
  surname?: string | null;
  /** Designates whether the user can log into this admin site. */
  is_staff?: boolean;
  counterparty_id?: number | null;
  available_park_ids?: number[] | null;
};
export type PatchedUserBaseInfoRead = {
  id?: number;
  first_name?: string;
  last_name?: string;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: string;
  surname?: string | null;
  role_id?: number | null;
  /** Designates whether the user can log into this admin site. */
  is_staff?: boolean;
  counterparty_id?: number | null;
  available_park_ids?: number[] | null;
};
export const {
  useApiAuthTokenAuthCreateMutation,
  useApiAuthTokenRefreshCreateMutation,
  useApiAuthTokenVerifyCreateMutation,
  useApiLoggerSystemListQuery,
  useApiUsersDeleteDestroyMutation,
  useApiUsersUpdatePartialUpdateMutation,
  useApiUsersCreateCreateMutation,
  useApiUsersListListQuery,
  useApiUsersSelfRetrieveQuery,
  useApiUsersSelfPartialUpdateMutation,
} = injectedRtkApi;
