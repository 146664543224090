import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { QUERY_PARAM } from '@shared/consts/server';
import { usePaginationState, useSortingState } from '@shared/ui/table/hooks';
import { ServerTableLayout } from '@shared/ui/table/layout';
import { useColumns } from '../../hooks/use-columns';
import { ReleasesOutputTable } from '@entities/driver/consts/types';
import { EParkSortEnum } from '@shared/api/services/integration/api';
import { useSortReleasesData } from '../../hooks/use-sort-releases-data';

type Props = {
  count: number | undefined;
  fetching: boolean;
  tableData: ReleasesOutputTable[];
  defaultPageSize: number;
};

export const ReleasesTableInstance: FC<Props> = ({
  count,
  fetching,
  tableData,
  defaultPageSize,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const columns = useColumns();

  const page = searchParams.get(QUERY_PARAM.page);
  const size = searchParams.get(QUERY_PARAM.limit);
  const ordering = searchParams.get(
    QUERY_PARAM.ordering
  ) as EParkSortEnum | null;
  const sortedField = searchParams.get(QUERY_PARAM.ordering) as
    | keyof ReleasesOutputTable
    | null;
  const sortedBy = searchParams.get(QUERY_PARAM.type);
  const type = searchParams.get(QUERY_PARAM.type); // as SortDirection | null;
  const sorting = useSortingState();
  const pagination = usePaginationState();

  const sortedData = useSortReleasesData({ tableData, sortedBy, sortedField });

  const paginatedData = useMemo(() => {
    const currentPage = Number(page ?? 1);
    const currentSize = Number(size ?? defaultPageSize);

    const startIdx = (currentPage - 1) * currentSize;
    const endIdx = startIdx + currentSize;
    return sortedData.slice(startIdx, endIdx);
  }, [sortedData, size, page]);

  const table = useReactTable({
    data: paginatedData,
    columns,
    state: {
      sorting,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (size ? Number(size) : defaultPageSize))
      : undefined,
  });

  return (
    <ServerTableLayout
      loading={fetching}
      table={table}
      rowsCount={count}
      defaultPageSize={defaultPageSize}
    />
  );
};
