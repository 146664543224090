import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SortDirection } from '@tanstack/react-table';

import { useAppSelector } from '@app/hooks';

import { selectLogsFilters } from '@shared/redux/slices/logs/filters';

import { useGetLogsQuery } from '@shared/api/services/user/enhanced';
// import {
//   EParkSortEnum,
//   SortDirection,
//   StationStatusEnum,
// } from '@shared/api/services/integration/api';
// import { useGetChargingStationsQuery } from '@shared/api/services/integration/enhanced';
import { QUERY_PARAM } from '@shared/consts/server';
import { countPageSize } from '@shared/ui/table/lib/count-page-size';

import { LogsTableInstance } from '../table';

export function ConnectedLogsTable() {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultPageSize = countPageSize();

  const page = searchParams.get(QUERY_PARAM.page);
  const size = searchParams.get(QUERY_PARAM.limit);
  const ordering = searchParams.get(QUERY_PARAM.ordering) as
    | 'datetime'
    | 'user'
    | 'action'
    | null;
  const type = searchParams.get(QUERY_PARAM.type) as SortDirection | null;

  const { actions, userIds, service, dateFrom, dateTo } =
    useAppSelector(selectLogsFilters);

  const {
    data: logs,
    isLoading,
    isFetching,
    isSuccess,
    error,
  } = useGetLogsQuery({
    actions: actions.length === 0 ? undefined : actions,
    userIds: userIds.length === 0 ? undefined : userIds,
    service,
    dateFrom:
      dateFrom === undefined || dateFrom === null
        ? undefined
        : dateFrom.format(),
    dateTo:
      dateTo === undefined || dateFrom === null ? undefined : dateTo.format(),
    page: page ? Number(page) : undefined,
    pageSize: size ? Number(size) : defaultPageSize,
    sortedBy: type ?? undefined,
    sortedField: ordering ?? undefined,
  });

  const tableData = useMemo(() => {
    if (!logs) {
      return [];
    }

    return logs.results;
  }, [logs]);

  if (isLoading) {
    return <div>Идет загрузка....</div>;
  }

  if (error) {
    return <div>Ошибка: не удалось загрузить данные по логам</div>;
  }

  if (isSuccess) {
    return (
      <LogsTableInstance
        tableData={tableData}
        fetching={isFetching && !isLoading}
        count={logs?.count ?? 1}
        defaultPageSize={defaultPageSize}
      />
    );
  }

  return <>Не удалось загрузить данные по логам</>;
}
