import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { Flex } from 'antd';
import dayjs from 'dayjs';

import { CustomSelect } from '@shared/ui/custom-select-new';
import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';
import {
  GroupOutput,
  ParkOutput,
  VehicleManufacturesRead,
  VehicleModelsRead,
  VehicleStatusesRead,
  VehicleTypesRead,
} from '@shared/api/services/common/api';
import { LightningIcon } from '@shared/icons/lightning';
import { Electricbus } from '@shared/api/services/electricbus/types';
import { useUpdateElectricbusMutation } from '@shared/api/services/electricbus/enhanced';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';

import { VehicleStatusBadge } from '@entities/vehicle/ui/vehicle-status-badge';
import { TrackerStatusBadge } from '@entities/vehicle/ui/tracker-status-badge';

import { EditVehicleButton } from '@features/vehicle/edit/ui/button';
import { SearchImeiSelect } from '@entities/vehicle/ui/search-imei-select';

import { StatusStyledSelect } from './styles';
// import { IdCell } from '../ui/id-cell';
import { SocCell } from '../../../../entities/vehicle/ui/soc-cell';
import { SohCell } from '../../../../entities/vehicle/ui/soh-cell';
import { idTitleColumnMap } from '../consts/id-title-column-map';
import { ElectricbusTableItem } from '../consts/types';
import { useRole } from '@entities/user/lib/hooks/use-role';

const columnHelper = createColumnHelper<ElectricbusTableItem>();

type Props = {
  statuses?: VehicleStatusesRead[];
};

export const useColumns = ({ statuses }: Props) => {
  // const dispatch = useDispatch();
  const [trigger, { isLoading }] = useUpdateElectricbusMutation();
  const {
    isDispatcher,
    isAdmin,
    isOperator,
    isLoading: isRoleLoading,
  } = useRole();

  // const settings = useSelector(selectUsersTable);

  const [settings, setSettings] = useState<Record<string, boolean>>({
    status_id: true,
    tracker_status: true,
    garage_number: true,
    grn: true,
    soc: true,
    soh: false, // true,
    output_number: false, // false, // Номер выхода временно прячем, пока нет данных
    counterparty: false, // false,
    type_id: false, // false,
    driver: false, // false, // Водитель временно прячем, пока нет данных
    phone: false, // false, // Телефон временно прячем, пока нет данных
    route: false, // false, // маршрут - временно прячем, пока нет данных
    last_charge_time: false, // false,
    manufacture_id: false, // false,
    model_id: false, // false,
    group_id: false, // false,
    tracker_id: true,
  });

  const DATA_COLUMNS = useMemo(() => {
    return [
      columnHelper.accessor('status', {
        // electrobus
        id: 'status_id',
        header: 'Статус ТС',
        cell: (props) => {
          const status = props.getValue();
          const vehicleUuid = props.row.original.id;

          if (!status) {
            return 'Неизвестный статус';
          }

          return (
            <StatusStyledSelect
              vehicleStatus={status}
              value={status.id}
              disabled={isRoleLoading || isOperator}
              onChange={(id) => {
                try {
                  const res = trigger({
                    status_id: id as number,
                    id: vehicleUuid,
                  }).unwrap();

                  openSuccessNotification('Статус ТС успешно обновлён');
                } catch (err) {
                  const hasErrorMessage = isErrorWithMessage(err);

                  const errorText = hasErrorMessage
                    ? err.data.detail
                    : 'Не удалось обновить статус ТС';

                  openErrorNotification(errorText);
                }
              }}
              options={
                statuses?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                })) ?? []
              }
            />
          );
        },
        size: 200,
        minSize: 200,
        maxSize: 200,
      }),
      columnHelper.accessor('tracker_status', {
        id: 'tracker_status',
        header: 'Статус трекера',
        cell: (props) => {
          const val = props.getValue();

          if (val === 'ONLINE' || val === 'OFFLINE') {
            return <TrackerStatusBadge status={val} />;
          }

          return '';
        },
        size: 180,
        minSize: 180,
        maxSize: 180,
        enableSorting: false,
      }),
      columnHelper.accessor('garage_number', {
        id: 'garage_number',
        header: () => {
          return (
            <p>
              Гаражный <br /> номер
            </p>
          );
        },
        size: 120,
        maxSize: 120,
        minSize: 120,
      }),
      columnHelper.accessor('grn', {
        id: 'grn',
        header: 'ГРЗ',
        size: 130,
        maxSize: 130,
        minSize: 130,
      }),
      columnHelper.accessor('soc', {
        id: 'soc',
        header: 'SoC',
        cell: SocCell,
        size: 110,
        maxSize: 110,
        minSize: 110,
        enableSorting: false,
      }),
      columnHelper.accessor('soh', {
        id: 'soh',
        header: 'SoH',
        cell: SohCell,
        size: 110,
        maxSize: 110,
        minSize: 110,
        enableSorting: false,
      }),
      // @ts-ignore
      columnHelper.accessor('', {
        id: 'output_number',
        header: 'Номер выхода',
        size: 135,
        minSize: 135,
        maxSize: 135,
        enableSorting: false,
      }),
      columnHelper.accessor('counterparty', {
        id: 'counterparty',
        header: 'Контрагент',
        size: 200,
        maxSize: 200,
        minSize: 200,
        enableSorting: false,
      }),
      columnHelper.accessor('type', {
        id: 'type_id',
        header: 'Тип ТС',
        cell: (props) => {
          return props.getValue()?.name ?? 'Нет данных';
        },
        size: 94,
        maxSize: 94,
        minSize: 94,
      }),
      // @ts-ignore
      columnHelper.accessor('', {
        id: 'driver',
        header: 'Водитель',
        size: 94,
        maxSize: 94,
        minSize: 94,
        enableSorting: false,
      }),
      columnHelper.accessor('phone_number', {
        id: 'phone',
        header: 'Телефон',
        cell: (props) => {
          return props.getValue() ?? 'нет данных';
        },
        size: 140,
        maxSize: 140,
        minSize: 140,
        enableSorting: false,
      }),
      // @ts-ignore
      columnHelper.accessor('', {
        id: 'route',
        header: 'Маршрут',
        size: 144,
        maxSize: 144,
        minSize: 144,
        enableSorting: false,
      }),
      columnHelper.accessor('last_charge_time', {
        id: 'last_charge_time',
        header: 'Время последней зарядки',
        cell: (props) => {
          const val = props.getValue();

          if (!val) {
            return '';
          }

          const dateString = dayjs(val).format('HH:mm DD.MM.YYYY');

          const [time, date] = dateString.split(' ');

          return (
            <div>
              <p>{time}</p>
              <p>{date}</p>
            </div>
          );
        },
        size: 234,
        minSize: 234,
        maxSize: 234,
        enableSorting: false,
      }),
      columnHelper.accessor('manufacturer', {
        id: 'manufacture_id',
        header: 'Производитель',
        cell: (props) => {
          return props.getValue()?.name ?? 'Нет данных';
        },
        size: 165,
        minSize: 165,
        maxSize: 165,
      }),
      columnHelper.accessor('model', {
        id: 'model_id',
        header: 'Модель',
        cell: (props) => {
          return props.getValue()?.name ?? 'Нет данных';
        },
        size: 100,
        minSize: 100,
        maxSize: 100,
      }),
      columnHelper.accessor('park_name', {
        id: 'group_id',
        header: 'Парк',
        size: 167,
        minSize: 167,
        maxSize: 167,
        enableSorting: false,
      }),
      columnHelper.accessor('imei', {
        id: 'tracker_id',
        header: 'ID трекера',
        cell: (props) => {
          const value = props.getValue();
          const vehicleUuid = props.row.original.id;

          if (isRoleLoading) {
            return null;
          }

          if (isDispatcher || isAdmin) {
            return <SearchImeiSelect value={value} evId={vehicleUuid} />;
          }

          return value ?? 'Нет данных';
        },
        size: 167,
        minSize: 167,
        maxSize: 167,
        enableSorting: false,
      }),
    ];
  }, [isDispatcher, isAdmin, isRoleLoading]);

  const settingsColumn = useMemo(() => {
    return getTableSettingsColumn({
      columnHelper,
      columns: DATA_COLUMNS.map(({ id, header }) => {
        const typedId = id!;
        return {
          key: typedId,
          label: idTitleColumnMap[typedId],
          isChecked: settings[typedId],
        };
      }),
      settings,
      renderCell: (props) => {
        const vehicle = props.row.original;

        if (isRoleLoading) {
          return null;
        }

        if (isDispatcher || isAdmin) {
          return <EditVehicleButton vehicle={vehicle} />;
        }

        return null;
      },
      setVisibleColumns: (cols: Record<string, boolean>) => {
        setSettings(cols);
      },
    });
  }, [isDispatcher, isAdmin, isOperator, isRoleLoading]);

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings, isAdmin, isOperator, isRoleLoading]);

  return visibleColumns;
};
