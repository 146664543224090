import { DatePicker } from 'antd';

import { StyledDateTimePicker } from './styles';

const CUSTOM_FORMAT = 'HH:mm, DD.MM.YY';
const SHOW_TIME_FORMAT = 'HH:mm';

type Props = React.ComponentProps<typeof DatePicker> & {
  offShowTime?: boolean;
  customFormat?: string;
};

export function CustomDatetimepicker(props: Props) {
  const isShowTimeToBeHidden = props.offShowTime;
  const { customFormat } = props;

  return (
    <StyledDateTimePicker
      {...props}
      showTime={isShowTimeToBeHidden ? false : { format: SHOW_TIME_FORMAT }}
      format={customFormat ?? CUSTOM_FORMAT}
      placeholder="Выбор даты"
    />
  );
}
