import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';

import { FormSchemaType } from '@entities/tracker/consts/schema';

import { ADD_SUCCESS, ADD_ERROR } from '../../consts';
import { ConnectedDriverForm } from '@entities/driver/ui/connected-form';
import { useCreateDriver } from '@shared/api/services/drivers/enhanced';
import {
  CreatingFormSchemaType,
  EditingFormSchemaType,
} from '@entities/driver/consts/schema';

type Props = {
  closeModal: () => void;
};

export function AddDriver({ closeModal }: Props) {
  const [create, { isLoading }] = useCreateDriver();

  const handleSubmit = async (data: CreatingFormSchemaType) => {
    try {
      const payload = {
        full_name: `${data.lastName} ${data.firstName}${
          data.surname ? ` ${data.surname}` : ''
        }`,
        mobile: data.mobile,
        personal_number: data.personalNumber,
        employee_number: data.employeeNumber,
        driving_license: data.drivingLicense,
        license_expiration_date:
          data.licenseExpirationDate?.format('YYYY-MM-DD'),
        park_id: data.parkId,
      };

      await create(payload).unwrap();

      openSuccessNotification(`${ADD_SUCCESS}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.detail : ADD_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <ConnectedDriverForm
      submitButtonLabel="Добавить"
      handleFormSubmit={
        handleSubmit as (
          data: Partial<EditingFormSchemaType> | CreatingFormSchemaType
        ) => Promise<void>
      }
      loading={isLoading}
      formType="creating"
    />
  );
}
