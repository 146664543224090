import { z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';

const dayjsType = z.custom<Dayjs>((data) => dayjs.isDayjs(data), {
  message: 'Expected an instance of dayjs',
});

export const FormSchema = z.object({
  actions: z.array(z.number()),
  userIds: z.array(z.number()),
  dateFrom: dayjsType.optional().nullable(),
  dateTo: dayjsType.optional().nullable(),
  service: z.string().optional(),
});

export type FormSchemaType = z.infer<typeof FormSchema>;
