import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

const dayjsType = z.custom<Dayjs>((data) => dayjs.isDayjs(data), {
  message: 'Expected an instance of dayjs',
});

export const FormSchema = z.object({
  parkIds: z.array(z.number()),
  dateFrom: z.union([z.string(), dayjsType]),
  dateTo: z.union([z.string(), dayjsType]),
});

export type FormSchemaType = z.infer<typeof FormSchema>;
