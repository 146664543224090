import { FC, useEffect, useMemo } from 'react';
import { ConfigProvider, Select } from 'antd';
import { useForm, Controller, type FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import locale from 'antd/locale/ru_RU';
import theme from 'antd/es/theme';
import { useAppDispatch, useAppSelector } from '@app/hooks';

import { CustomInput } from '@shared/ui/custom-input';
import {
  selectLogsFilters,
  setFilters,
} from '@shared/redux/slices/logs/filters';
import { resetPageByFiltering } from '@shared/utils/table/reset-page-by-filtering';
import {
  CustomSelect,
  CustomSelectMultiple,
} from '@shared/ui/custom-select-new';

import { FormSchema, FormSchemaType } from '../../consts/schema';
import { UiButton } from '@shared/ui/ui-button';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';

import {
  FormButtonsContainer,
  FormLayout,
  InputGroupContent,
  StyledAntdSelect,
} from './styles';
import { useData } from '../../hooks/use-data';
import { CustomDatetimepicker } from '@shared/ui/custom-datetimepicker';

type Props = {
  closeModal: () => void;
};

export const FiltersForm: FC<Props> = ({ closeModal }) => {
  const filters = useAppSelector(selectLogsFilters);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { users, isLoading, error } = useData();

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: filters,
  });

  const actions = watch('actions');
  const userIds = watch('userIds');
  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');
  const service = watch('service');

  const onSubmit = async (data: FieldValues) => {
    resetPageByFiltering(searchParams, setSearchParams);
    dispatch(setFilters(data));

    closeModal();
  };

  const handleReset = () => {
    setValue('actions', []);
    setValue('userIds', []);
    setValue('dateFrom', undefined);
    setValue('dateTo', undefined);
    setValue('service', undefined);
  };

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Ошибка</div>;
  }

  const usersOptions =
    users?.map((user) => ({
      value: user.id,
      label: user.username,
    })) ?? [];

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="password"
      >
        <FormLayout>
          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>Сервис</FormItemLabel>
              <Controller
                name="service"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <CustomSelect
                      {...field}
                      value={service}
                      //  placeholder={'Выберите сервис'}
                      options={[
                        { value: 'electricbus', label: 'Электробусы' },
                        { value: 'tracker', label: 'Трекеры' },
                        { value: 'user', label: 'Пользователи' },
                      ]}
                      allowClear
                      onClear={() => setValue('service', '')}
                    />
                  );
                }}
              />
              <FieldErrorContainer></FieldErrorContainer>
            </FormItemContainer>
            <FormItemContainer>
              <FormItemLabel>Действия</FormItemLabel>
              <Controller
                name="actions"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomSelectMultiple
                    {...field}
                    options={[
                      { value: 0, label: 'Создание' },
                      { value: 1, label: 'Редактирование' },
                      { value: 2, label: 'Удаление' },
                    ]}
                  />
                )}
              />
              <FieldErrorContainer></FieldErrorContainer>
            </FormItemContainer>
          </FormColumn>
          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>Пользователи</FormItemLabel>
              <Controller
                name="userIds"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomSelectMultiple {...field} options={usersOptions} />
                )}
              />
              <FieldErrorContainer></FieldErrorContainer>
            </FormItemContainer>
            <FormItemContainer>
              <FormItemLabel>Период логирования</FormItemLabel>
              <InputGroupContent>
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomDatetimepicker {...field} value={dateFrom} />
                  )}
                />
                {'-'}
                <Controller
                  name="dateTo"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomDatetimepicker {...field} value={dateTo} />
                  )}
                />
              </InputGroupContent>
              <FieldErrorContainer>
                {errors?.dateFrom && <p>{errors.dateFrom.message}</p>}
                {errors?.dateTo && <p>{errors.dateTo.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
          </FormColumn>
        </FormLayout>
        <FormButtonsContainer>
          <UiButton variant="outline" onClick={handleReset} type="button">
            Сбросить
          </UiButton>
          <UiButton variant="primary" type="submit">
            Применить
          </UiButton>
        </FormButtonsContainer>
      </form>
    </ConfigProvider>
  );
};
