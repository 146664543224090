import { HeaderLayout } from '../../layout';
import { useParams } from 'react-router-dom';
import { useParkVehicleData } from '@entities/vehicle/hooks/use-park-vehicle-data';

import { ContentWrapper, Title } from '../../consts/styles';
import { Outer } from './styles';

export function ElectrobusExpandedInfoPageHeader() {
  const { eParkId, convoyId, eBusId } = useParams();

  const { parks, electricbuses, isSuccess } = useParkVehicleData({});

  const park = parks?.find(({ id }) => Number(id) === Number(eParkId));
  const convoy = park?.groups?.find(({ id }) => String(id) === convoyId);
  const ebus = electricbuses?.find(({ id }) => id === eBusId);

  const title = `${park?.name ?? ''}, ${convoy?.name ?? ''}, ${
    ebus?.grn ?? ''
  }`;

  const content = (
    <ContentWrapper>
      <Title>{isSuccess ? title : ''}</Title>
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
}
