import {
  useGetGroupsQuery,
  useGetManufacturersQuery,
  useGetVehicleModelsQuery,
  useGetVehicleStatusesQuery,
  useGetVehicleTypesQuery,
} from '@shared/api/services/common/enhanced';
import { useGetTrackerByImeiQuery } from '@shared/api/services/tracker/enhanced';

type Props = {
  imei: string;
};

export function useData({ imei }: Props) {
  const {
    data: tracker,
    isLoading: isTrackerLoading,
    error: trackerError,
    isSuccess: isTrackerSucccess,
  } = useGetTrackerByImeiQuery(imei, { skip: !Boolean(imei) });

  return {
    tracker,
  };
}
