import { createSlice } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';

type State = {
  actions: number[];
  userIds: number[];
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  service?: 'electricbus' | 'tracker' | 'user';
};

const initialState: State = {
  actions: [],
  userIds: [],
};

const slice = createSlice({
  name: 'logsFilters',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { setFilters } = slice.actions;

export const selectLogsFilters = (state: RootState) => state.logsFilters;

export const selectHasFilters = (state: RootState) => {
  const { actions, userIds, dateFrom, dateTo, service } = state.logsFilters;

  if (
    [dateFrom, dateTo, service].some((el) => el !== undefined) ||
    actions.length !== 0 ||
    userIds.length !== 0
  ) {
    return true;
  }

  return false;
};

export default slice.reducer;
