import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

type State = {
  parkIds: Array<number>;
  dateFrom: string;
  dateTo: string;
};

const initialState: State = {
  parkIds: [],
  dateFrom: dayjs().format('YYYY-MM-DD'),
  dateTo: dayjs().format('YYYY-MM-DD'),
};

const slice = createSlice({
  name: 'releasesFilters',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { setFilters } = slice.actions;

export const selectReleasesFilters = (state: RootState) =>
  state.releasesFilters;

export default slice.reducer;
