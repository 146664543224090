import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    // водители:
    getDriversApiDriversGet: (endpoint) => {
      endpoint.query = (parkIds) => {
        return {
          url: `driver/api/drivers/`,
          params: { park_ids: parkIds },
        };
      };
      endpoint.providesTags = ['Drivers'];
    },
    updateDriverApiDriversPatch: (endpoint) => {
      endpoint.query = (queryArg) => {
        return {
          url: `driver/api/drivers/`,
          method: 'PATCH',
          body: queryArg.driverUpdate,
          // @ts-ignore
          params: { driver_id: queryArg.id },
        };
      };
      endpoint.invalidatesTags = ['Drivers'];
    },
    createDriverApiDriversPost: (endpoint) => {
      endpoint.query = (body) => ({
        method: 'POST',
        url: `driver/api/drivers/`,
        body,
      });
      endpoint.invalidatesTags = ['Drivers'];
    },
    uploadDriversApiDriversUploadDriversPost: (endpoint) => {
      endpoint.query = ({
        parkId,
        bodyUploadDriversApiDriversUploadDriversPost,
      }) => {
        return {
          method: 'POST',
          url: `driver/api/drivers/upload_drivers/`,
          params: {
            park_id: parkId,
          },
          body: bodyUploadDriversApiDriversUploadDriversPost,
        };
      };
      endpoint.invalidatesTags = ['Drivers'];
    },
    // наряды:
    getDailyReleasesApiDailyReleasesGet: (endpoint) => {
      endpoint.query = (queryArg) => {
        return {
          url: `driver/api/daily_releases/`,
          params: {
            date_from: queryArg.dateFrom,
            date_to: queryArg.dateTo,
            park_ids: queryArg.parkIds,
          },
        };
      };
      endpoint.providesTags = ['Releases'];
    },
    getDailyReleasesWithDriversApiDailyReleasesWithDriverGet: (endpoint) => {
      endpoint.query = (queryArg) => {
        return {
          url: `driver/api/daily_releases/with_driver`,
          params: {
            garage_number: queryArg.garageNumber,
            park_id: queryArg.parkId,
          },
        };
      };
      endpoint.providesTags = ['ReleaseWithDriver'];
    },
    uploadDailyReleasesApiDailyReleasesUploadDailyReleasesPost: (endpoint) => {
      endpoint.query = ({
        parkId,
        bodyUploadDailyReleasesApiDailyReleasesUploadDailyReleasesPost,
      }) => {
        return {
          method: 'POST',
          url: `driver/api/daily_releases/upload_daily_releases/`,
          params: {
            park_id: parkId,
          },
          body: bodyUploadDailyReleasesApiDailyReleasesUploadDailyReleasesPost,
        };
      };
      endpoint.invalidatesTags = ['Releases'];
    },
  },
});

export const {
  useGetDriversApiDriversGetQuery: useGetDrivers,
  useUpdateDriverApiDriversPatchMutation: useUpdateDriver,
  useCreateDriverApiDriversPostMutation: useCreateDriver,
  useUploadDriversApiDriversUploadDriversPostMutation: useUploadDrivers,
  useGetDailyReleasesApiDailyReleasesGetQuery: useGetReleases,
  useLazyGetDailyReleasesWithDriversApiDailyReleasesWithDriverGetQuery:
    useLazyReleaseWithDriver,
  useUploadDailyReleasesApiDailyReleasesUploadDailyReleasesPostMutation:
    useUploadReleases,
} = enhancedApi;
