import { createSlice } from '@reduxjs/toolkit';

type State = {
  parkIds: Array<number>;
};

const initialState: State = {
  parkIds: [],
};

const slice = createSlice({
  name: 'driversFilters',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { setFilters } = slice.actions;

export const selectDriversFilters = (state: RootState) => state.driversFilters;

export const selectHasFilters = (state: RootState) => {
  const { parkIds } = state.driversFilters;

  if ([parkIds].some((el) => el.length !== 0)) {
    return true;
  }

  return false;
};

export default slice.reducer;
