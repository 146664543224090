import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';
import { LogEntryOutputRead } from '@shared/api/services/user/api';
import dayjs from 'dayjs';
import { ChangesLogUser } from '@entities/user/ui/changes-log-user';

const columnHelper = createColumnHelper<LogEntryOutputRead>();

export const useColumns = () => {
  const [settings, setSettings] = useState<Record<string, boolean>>({
    action: true,
    actor: true,
    object_repr: true,
    timestamp: true,
    changes: true,
  });

  const DATA_COLUMNS = [
    columnHelper.accessor('action', {
      id: 'action',
      header: 'Действие',
      size: 150,
      maxSize: 150,
      minSize: 150,
      cell: (props) => {
        switch (props.getValue()) {
          case 0:
            return 'Создание';
          case 1:
            return 'Редактирование';
          case 2:
            return 'Удаление';
          default:
            'Нет данных';
        }
      },
    }),
    columnHelper.accessor('actor', {
      id: 'actor',
      header: 'Пользователь',
      size: 160,
      maxSize: 160,
      minSize: 160,
      cell: (props) => {
        const value = props.getValue();

        return value?.username ?? 'Нет данных';
      },
    }),
    columnHelper.accessor('object_repr', {
      id: 'object_repr',
      header: 'Сущность',
      size: 214,
      maxSize: 214,
      minSize: 214,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: false,
    }),
    columnHelper.accessor('timestamp', {
      id: 'timestamp',
      header: 'Дата и время',
      size: 150,
      maxSize: 150,
      minSize: 150,
      cell: (props) => {
        const value = props.getValue();
        if (value === null || value === undefined) {
          return 'Нет данных';
        }

        const [date, time] = dayjs(value).format('YYYY.MM.DD HH:mm').split(' ');

        return `${date},  ${time}`;
      },
    }),
    columnHelper.accessor('changes', {
      id: 'changes',
      header: 'Изменение',
      size: 500,
      maxSize: 500,
      minSize: 500,
      cell: (props) => {
        const stringifiedValue =
          JSON.stringify(props.getValue()) ?? 'Нет данных';
        return <ChangesLogUser changes={stringifiedValue} />;
      },
      enableSorting: false,
    }),
  ];

  const settingsColumn = getTableSettingsColumn({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      const typedId = id!;
      return {
        key: id as string,
        label: header as string,
        isChecked: settings[typedId],
      };
    }),
    settings,
    renderCell: (props) => null,
    setVisibleColumns: (cols: Record<string, boolean>) => {
      setSettings(cols);
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
