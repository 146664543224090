import { ReleasesOutputTable } from '@entities/driver/consts/types';
import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { GetParksApiParksGetApiResponse } from '@shared/api/services/common/api';
import {
  useGetDrivers,
  useGetReleases,
} from '@shared/api/services/drivers/enhanced';
import { useAppSelector } from '@app/hooks';
import { selectReleasesFilters } from '@shared/redux/slices/releases/filters';
import {
  GetDailyReleasesApiDailyReleasesGetApiResponse,
  GetDriversApiDriversGetApiResponse,
} from '@shared/api/services/drivers/api';
import { useMemo } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

export const useData = (): {
  tableData: ReleasesOutputTable[];
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  error?: FetchBaseQueryError | SerializedError;
} => {
  const { parkIds, dateFrom, dateTo } = useAppSelector(selectReleasesFilters);

  const {
    data: parks = [],
    isLoading: isParksLoading,
    isFetching: isParksFetching,
    isSuccess: isParksSuccess,
    error: parksError,
  } = useGetGroupsQuery(undefined);

  const {
    data: releases = [],
    isLoading: isOrdersLoading,
    isFetching: isOrdersFetching,
    isSuccess: isOrdersSuccess,
    error: ordersError,
  } = useGetReleases({
    parkIds: parkIds.length === 0 ? undefined : parkIds.join(','),
    dateFrom: dateFrom,
    dateTo: dateTo,
  });

  const isLoading = isOrdersLoading || isParksLoading;

  const isFetching = isOrdersFetching;

  const isSuccess = isOrdersSuccess && isParksSuccess;

  const error = [parksError, ordersError].find((err) => err !== undefined);

  const tableData: ReleasesOutputTable[] = useMemo(() => {
    if (releases.length === 0) return [];

    return releases.map((release) => {
      const park = parks.find(({ id }) => release.park_id === id);

      return {
        id: release.id,
        driver_id: release.driver_id,
        park_id: release.park_id,
        full_name: release?.full_name,
        garage_number: release.garage_number, // верно
        employee_number: release?.employee_number,
        route: release.route,
        order_number: release.order_number,
        date: release.date,
        start_time: release.start_time,
        end_time: release.end_time,
        park_name: park?.name,
      };
    });
  }, [parks, releases]);

  return { tableData, isLoading, isSuccess, isFetching: false };
};
