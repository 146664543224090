
import { FiltersButton } from '@features/logs/filters/ui';
import { HeaderLayout } from '@widgets/header/layout';
import { ContentWrapper, Outer, Title } from '@widgets/header/consts/styles';

export function LogsHeader() {
  const content = (
    <ContentWrapper>
      <Title>Логи</Title>
      <FiltersButton />
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
}
